
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { ProgramTrackItem } from '@/entities/Program/ProgramTrackItem';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { ProgramSignalRModel } from '@/signalRRequest/Program/ProgramSignalRModel';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { Root, Store } from '@/main';
@Component({
  components: {
    ValidationSummary,
  },
})
class ProgramAssignTracks extends Vue {
  @Prop() private trackItems?: ProgramTrackItem[];
  @Prop() private selectedItems?: ProgramItem[];
  @Prop() private selectedBulkOperationModel?: SignalRModelRequest;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private localTrackItems: ProgramTrackItem[] = [];
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private signalR: ProgramSignalRModel = new ProgramSignalRModel(
    Root,
    Store,
    this.$t,
  );
  private GetImageUrl(imgurl: string) {
    if (imgurl === null || imgurl === '') {
      return this.missingImage;
    }
    return imgurl;
  }
  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  private mounted() {
    this.localSelectedBulkOperationModel = this.selectedBulkOperationModel!;
    this.localTrackItems = this.trackItems!;
    this.UncheckAllTracks();
  }
  private addItem(item: ProgramTrackItem) {
    const objIndex = this.localTrackItems.findIndex(
      (obj: ProgramTrackItem) => obj.Id === item.Id,
    );
    this.UncheckAllTracks();
    item.IsSelected = true;
    this.localTrackItems[objIndex] = item;
  }
  private removeItem(item: ProgramTrackItem) {
    item.IsSelected = false;
    const objIndex = this.localTrackItems.findIndex(
      (obj: ProgramTrackItem) => obj.Id === item.Id,
    );
    this.UncheckAllTracks();
    this.localTrackItems[objIndex] = item;
  }
  private UncheckAllTracks() {
    this.localTrackItems.forEach((element) => {
      element.IsSelected = false;
    });
  }
  private CloseTrack() {
    this.$emit('selectBack');
  }

  private SaveTrack() {
    const objIndex = this.localTrackItems.findIndex(
      (obj: ProgramTrackItem) => obj.IsSelected === true,
    );
    if (objIndex > -1) {
      const selectedTrack: ProgramTrackItem = this.localTrackItems[objIndex];
      const selectedProgramIds: number[] = this.signalR.GetSelectedProgramIdsToSendInJson(this.selectedItems!);
      this.localSelectedBulkOperationModel.SelectedIds = selectedProgramIds;
      this.localSelectedBulkOperationModel.JSONData = JSON.stringify({trackId : selectedTrack.Id});
      setTimeout(() => {
        Root.emit('show-notification-popup', true);
        Root.emit('show-notification-loading', true);
      }, 100);
      this.localSelectedBulkOperationModel.Heading = this.$t(
        this.localSelectedBulkOperationModel.Heading,
      ).toString();
      this.localSelectedBulkOperationModel.Description = this.$t(
        this.localSelectedBulkOperationModel.SubmitMessage,
        {
          selectedCount: this.selectedItems!.length,
          user: this.signalR.GetUserName(this.localSelectedBulkOperationModel, this.$t('UserName.You').toString()),
        },
      ).toString();
      this.signalR.PerformProgramBatchOperation(
        this.localSelectedBulkOperationModel,
      );
      Root.emit('program-uncheck-select-all');
      Root.emit('empty-track-tag');
    } else {
      this.validationErrorFields = [];
      this.validationErrorFields.push(this.$t('Tracks.SelectTrack').toString());
      this.visibleValidationSummary = true;
    }
  }
  private GetColor(color: string) {
    // set color square background color
    return {
      background:  color,
      border: '2px solid #525252',
    };
  }
}
export default toNative(ProgramAssignTracks);
