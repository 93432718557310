
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { ProgramTrackItem } from '@/entities/Program/ProgramTrackItem';
import ProgramTrackItemEditPane from '@/components/Program/ProgramDetail/Tracks/ProgramTrackEditItemPane.vue';
import ProgramTrackListViewEditItemPane from '@/components/Program/ProgramDetail/Tracks/ProgramTrackListViewEditItemPane.vue';
import ImageProcess from '@/components/Common/ImageProcess.vue';
import { setTimeout } from 'timers';
import Common from '@/helper/Common';
import { Root } from '@/main';
@Component({
  components: {
    ProgramTrackItemEditPane,
    ProgramTrackListViewEditItemPane,
    ImageProcess,
  },
})
class ProgramTrackListView extends Vue {
  @Prop() private trackItems?: ProgramTrackItem[];
  @Prop() private selectedTrackItem?: ProgramTrackItem;
  @Prop() private applicationborderColor?: string;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private localTrackItem: ProgramTrackItem | null = null;
  private currentEditTrackItem: ProgramTrackItem | null = null;
  private showImageProcess = false;
  private isMobileView = false;
  public loaderBorderColor = '';
  private GetImageUrl() {
    let trackItem;
    if (this.currentEditTrackItem != null) {
      trackItem = this.currentEditTrackItem;
    } else {
      trackItem = this.localTrackItem;
    }
    return !trackItem!.IconUrl ? this.missingImage : trackItem!.IconUrl;
  }
  private ClosePreviouslyOpenTracksForEdit() {
    Root.emit('program-track-close-others-operation');
  }
  private AddTrack() {
    this.ClosePreviouslyOpenTracksForEdit();
    const newItem = ProgramTrackItem.createEmpty();
    this.localTrackItem = newItem;
    this.RedirectToItem(this.trackItems!.length);
  }
  private CloseTrack() {
    this.localTrackItem = null;
    this.$emit('program-track-close-operation', true);
    Root.emit('clearCheckboxes', []);
  }
  private mounted() {
    // check for ipad and mobile view
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    Root.on(
      'program-track-item-edit-operation',
      (pt: ProgramTrackItem) => {
        this.programTrackItemCancelOperation(pt);
      },
    );

    Root.on('program-track-close-others-operation', () => {
      this.currentEditTrackItem = null;
      this.localTrackItem = null;
    });
    // move scroll to top when this pane will open.
    if (!this.isMobileView) {
      Common.ScrollToTop('programListViewPane');
    }
  }

  private setCurrentlocalItem(pt: ProgramTrackItem) {
    this.currentEditTrackItem = pt;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private programTrackItemCancelOperation(pt: ProgramTrackItem) {
    this.localTrackItem = null;
  }

  private programTrackItemImageUploadCompleted(pt: ProgramTrackItem) {
    this.localTrackItem = pt;
  }
  private GetImageUploadUrl() {
    let url = this.missingImage;
    if (this.currentEditTrackItem != null) {
      url =
        this.currentEditTrackItem.IconUrlOriginal === null
          ? this.missingImage
          : this.currentEditTrackItem.IconUrlOriginal === ''
            ? this.missingImage
            : this.currentEditTrackItem.IconUrlOriginal;
    } else if (this.localTrackItem != null) {
      url =
        this.localTrackItem.IconUrlOriginal === null
          ? this.missingImage
          : this.localTrackItem.IconUrlOriginal === ''
            ? this.missingImage
            : this.localTrackItem.IconUrlOriginal;
    }
    return url;
  }
  private clickOnImage(val: boolean) {
    this.showImageProcess = val;
    if (val === true) {
      const el = this.$el.getElementsByClassName('flex-container')[0];
      if (el) {
        el.scrollIntoView();
      }
    } else {
      this.scrollToElement();
    }

    if (this.currentEditTrackItem != null && val === false) {
      setTimeout(() => {
        Root.emit(
          'program-track-item-imageupdated-operation',
          this.currentEditTrackItem,
        );
      }, 100);
    }
  }
  private SetImageIconUrl(iconUrl: string, iconUrlOriginal: string) {
    if (this.localTrackItem != null) {
      this.localTrackItem.IconUrl = iconUrl;
      this.localTrackItem.IconUrlOriginal = iconUrlOriginal;
    } else if (this.currentEditTrackItem != null) {
      this.currentEditTrackItem.IconUrl = iconUrl;
      this.currentEditTrackItem.IconUrlOriginal = iconUrlOriginal;
    }
  }
  private GetPreviousIconUrl(iconUrl: string, iconUrlOriginal: string) {
    if (this.localTrackItem != null) {
      this.localTrackItem.OldIconUrl = iconUrl;
      this.localTrackItem.OldIconUrlOriginal = iconUrlOriginal;
    } else if (this.currentEditTrackItem != null) {
      this.currentEditTrackItem.OldIconUrl = iconUrl;
      this.currentEditTrackItem.OldIconUrlOriginal = iconUrlOriginal;
    }
  }
  // scroll to particular element when come back from image process component
  private scrollToElement() {
    setTimeout(() => {
      const el = document.getElementById('programTrackItemEditPane');
      if (el) {
        el.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
          inline: 'nearest',
        });
      }
    }, 100);
  }
  private RedirectToItem(index: number) {
    setTimeout(() => {
      const element = document.querySelectorAll('[indexTrack="' + index + '"]')[0];
      if (element) {
        element.scrollIntoView({
          block: 'end',
          behavior: 'smooth',
          inline: 'end',
        });
      }
    }, 100);
  }
}
export default toNative(ProgramTrackListView);
