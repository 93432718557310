/* eslint-disable max-len */
import { ProgramSessionQuestionsSignalRModel } from '@/signalRRequest/Program/ProgramSessionQuestionsSignalRModel';
import { BaseBulkOperationsModel } from '../BaseBulkOperationsModel';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
export class SessionQuestionBulkOperations extends BaseBulkOperationsModel {
  constructor(rootObj: any, $store: any, $t: any) {
    super();

    const signalR: ProgramSessionQuestionsSignalRModel = new ProgramSessionQuestionsSignalRModel(rootObj, $store, $t);
    this.arrBulkOperations.push({ Id: 1, Name: 'Delete', IconUrl: 'fa fa-trash-o font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Permanently delete your selected items', ConfirmationMessage: 'Notifications.SureDeleteSessionQuestionsHeader1', ConfirmationMessageSubTitle: 'Notifications.SureDeleteSessionQuestionsHeader2', objModel: signalR.DeleteSelectedSessionQuestionsSignalRModelRequest, ShowConfirmationPopup: true  });

    this.arrBulkOperations.push({ Id: 2, Name: 'Add to Program', IconUrl: 'fa fa-calendar-check-o font-awesome-icon', IconPadding: 'padding:17px 17px 17px 0px;', Description: 'Assign selected items to sessions in your program', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: new SignalRModelRequest(), ShowConfirmationPopup: false });
  }
}
