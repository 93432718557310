
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { ProgramTagItem } from '@/entities/Program/ProgramTagItem';
import loading from '@/components/Common/loading.vue';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { TagSignalRModel } from '@/signalRRequest/Program/TagSignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { FetchData, Root, Store } from '@/main';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { reactive } from 'vue';
@Component({
  components: { loading },
})
class ProgramTagItemEditPane extends Vue {
  public showLoading = false;
  public loaderBorderColor = '';
  public initialTagItem: ProgramTagItem = ProgramTagItem.createEmpty();
  @Prop() private selectedTagItem?: ProgramTagItem;
  @Prop() private applicationborderColor?: string;
  private state = reactive({
    initialTagItem: null as ProgramTagItem | null,
  });

  private rules = {
    initialTagItem: {
      TagName: {
        required,
        minLength: minLength(1),
      },
    },
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);
  private mounted() {
    // store initial value of tag for cancel operation
    this.initialTagItem = Object.assign({}, this.selectedTagItem);
    this.state.initialTagItem = this.initialTagItem;
  }
  private Cancel(val: any) {
    val.$reset();
    this.initialTagItem = Object.assign({}, this.selectedTagItem);
    const item = this.selectedTagItem;
    this.$emit('program-tag-item-cancel-operation', item);
    this.state.initialTagItem = this.initialTagItem;
  }
  private SendNotificationRequest(): SignalRModelRequest {
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    const signalR: TagSignalRModel = new TagSignalRModel(
      Root,
      Store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();

    if (this.initialTagItem.Id === 0) {
      signalObject = signalR.SingleCreateTagItemSignalRModelRequest;
    } else {
      signalObject = signalR.SingleUpdateTagItemSignalRModelRequest;
      const arr: number[] = [];
      arr.push(this.initialTagItem.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
    }).toString();

    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({selectedName: this.initialTagItem.TagName});
    signalR.AddToNotificationPopup(signalObject);
    return signalObject;
  }

  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    programTagItem: ProgramTagItem,
  ) {
    const signalR: TagSignalRModel = new TagSignalRModel(
      Root,
      Store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(programTagItem.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = signalR.GetTagItemSendToSignalR(programTagItem);
    signalR.PerformTagSingleOperation(signalObject);
  }
  private async Submit(val: any) {
    val.$touch();
    const res = !await val.$validate();
    if(res) {
      return;
    }
    // No errors submitting.
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    const signalObject = this.SendNotificationRequest();
    FetchData('/Program/SaveOrUpdateProgramTag',
      JSON.stringify(this.initialTagItem))
      .then((response) => {
        if (response.ValidationErrors.length > 0) {
          // Server side validation failuere
          alert(this.$t(response.ValidationErrors[0]));
        } else {
          // Successful update / save
          this.$emit('program-tag-item-edit-operation', response.TagItem);

          Root.emit(
            'program-tag-item-edit-operation',
            response.TagItem,
          );
          this.SendNotificationRequestToQueue(
            signalObject,
            response.TagItem as ProgramTagItem,
          );
        }
      })
      .catch(() => {
        // Log the error if required
      });
  }
  private getPlaceholder(): string {
    return this.$t('Tag.Create.Placeholder').toString();
  }
}
export default toNative(ProgramTagItemEditPane);
