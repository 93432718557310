/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
import { BaseSignalRModel } from '../BaseSignalRModel';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { SignalRModelRequest } from '../SignalRModelRequest';
import { ProgramSignalRModel } from './ProgramSignalRModel';
import SubModuleEnum from '@/enums/SubModuleEnum';
import ModuleEnum from '@/enums/ModuleEnum';
import { ProgramTagItem, ProgramTagItemSignalR } from '@/entities/Program/ProgramTagItem';
import NotificationStatusEnum from '@/enums/NotificationStatusEnum';
import { app } from '@/main';

const TagSignalRModelRequestIds = {
  SingleCreate: 1,
  SingleEdit: 2,
  SingleDelete: 3,
};

export class TagSignalRModel extends BaseSignalRModel {
  // Get SignalRModelRequest for adding a single tag item
  get SingleCreateTagItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(TagSignalRModelRequestIds.SingleCreate);
  }
  // Get SignalRModelRequest for Updating a single tag item
  get SingleUpdateTagItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(TagSignalRModelRequestIds.SingleEdit);
  }
  // Get SignalRModelRequest for Deleting a single tag item
  get SingleDeleteTagItemSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(TagSignalRModelRequestIds.SingleDelete);
  }
  readonly programBatchOperationURL = '/BulkOperations/PerformBatchOperation';
  readonly programPostToClientsSignalRURL = '/BulkOperations/PostToClientsSignalR';
  constructor(public rootObj: any, public $store: any, public $t: any) {
    super(rootObj, $store);
    const reqInfo: AppRequestInfo = this.GetRequestInfoFromStore();
    const loggedInUserFullName = reqInfo.FirstName + ' ' + reqInfo.LastName;
    const baseUrl: string = app.config.globalProperties.$SignalRBaseURL;
    const singleItemChangeUrl = baseUrl + 'api/SignalRProgram/PushProgramSignalRChanges';
    this.arrSignalRModelRequests.push(new SignalRModelRequest(TagSignalRModelRequestIds.SingleCreate, ModuleEnum.Tag, SubModuleEnum.SingleCreate, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, singleItemChangeUrl, [], 'Notifications.SaveOperation', '', 'Notifications.SavingTagItem', 'Notifications.SavedTagItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleTag'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(TagSignalRModelRequestIds.SingleEdit, ModuleEnum.Tag, SubModuleEnum.SingleEdit,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, singleItemChangeUrl, [], 'Notifications.UpdateOperation', '', 'Notifications.UpdatingTagItem', 'Notifications.UpdatedTagItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleTag'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(TagSignalRModelRequestIds.SingleDelete, ModuleEnum.Tag, SubModuleEnum.SingleDelete,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, singleItemChangeUrl, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteSingleTagItem', 'Notifications.DeletedSingleTagItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleTag'));
  }
  // Add to Notification Popup that an operation has started
  public AddToNotificationPopup(signalRModelRequest: SignalRModelRequest) {
    this.EmitAddNewNotifications(signalRModelRequest);
  }
  // Get Tag Item send to SignalR as JSON data
  public GetTagItemSendToSignalR(programTagItem: ProgramTagItem): string {
    const item: ProgramTagItemSignalR = ProgramTagItemSignalR.GetProgramTagsItemSignalR(programTagItem);
    return JSON.stringify(item);
  }
  // Perform Tag Single Operations
  public PerformTagSingleOperation(signalRModelRequest: SignalRModelRequest) {
    this.SendSignalRModelRequestToHubWithoutAddingToQueue(signalRModelRequest, this.programPostToClientsSignalRURL);
    this.SendSignalRModelRequestToQueueWithoutEmit(signalRModelRequest, this.programBatchOperationURL);
  }
  // Method that fires when Tag SignalR response model is received from Hub
  public TagSignalRModelResponseReceived(signalRModelRequest: SignalRModelRequest) {
    this.SignalRModelResponseReceived(signalRModelRequest);
  }
}
