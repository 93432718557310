
import ActionButtonViewModel from '@/entities/ActionButtonViewModel';
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
@Component({
  components: {

  },
})
class ActionButton extends Vue {
  @Prop() actionButtonData!: ActionButtonViewModel;
  private localActionButtonData: ActionButtonViewModel = ActionButtonViewModel.createEmpty();
  private mounted() {
    if(this.actionButtonData !== null && this.actionButtonData !== undefined) {
      this.localActionButtonData = this.actionButtonData;
    }
  }
  private EmitActionType(type: number) {
    this.$emit('show-upload-bulk-data',type);
  }
  @Watch('actionButtonData')
  private CheckChangeInActionButtonData(newVal: ActionButtonViewModel) {
    this.localActionButtonData = newVal;
  }
}
export default toNative(ActionButton);
