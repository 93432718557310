
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import ActionButtonType from '@/enums/ActionButtonType';
import SubUploadBulkDataViewModel from '@/entities/ImportFile/SubUploadBulkDataViewModel';
import UploadBulkFileViewModel from '@/entities/ImportFile/UploadBulkFileViewModel';
import { ImportFileService } from  '@/entities/ImportFile/ImportFileService';
import readXlsxFile from 'read-excel-file';
import PreviewBulkData from './PreviewBulkData.vue';
import PreviewBulkDataViewModel from '@/entities/ImportFile/PreviewBulkDataViewModel';
import SubModuleEnum from '@/enums/SubModuleEnum';
import { FetchData, Root } from '@/main';
import ApiContentType from '@/enums/ApiContentType';
@Component({
  components: {
    PreviewBulkData,
  },
})
class UploadBulkData extends Vue {
  @Prop() moduleType!: number;
  @Prop() clickedButtonType!: number;
  private bulkUploadData: UploadBulkFileViewModel = UploadBulkFileViewModel.createEmpty();
  private subUploadBulkData: SubUploadBulkDataViewModel = SubUploadBulkDataViewModel.createEmpty();
  private uploadFileIcon = require('@/assets/Images/upload-file.svg');
  private currentInstance: any;
  private importedData: string[] =[];
  private tableHeadings: string[] = [];
  private previewData: PreviewBulkDataViewModel = PreviewBulkDataViewModel.createEmpty();
  private actionButtonType = ActionButtonType.Create;
  private uploadError = '';
  private supportedExtension = '.xlsx';
  private fileDownload = require('js-file-download');
  private maxImports = 500;
  private mounted() {
    if(this.moduleType!== null && this.moduleType!== undefined) {
      this.bulkUploadData = UploadBulkFileViewModel.GetUploadBulkFileData(this.moduleType);
    }
    if(this.clickedButtonType === ActionButtonType.Create) {
      this.subUploadBulkData = this.bulkUploadData.SubUploadBulkData[ActionButtonType.Create];
      this.actionButtonType = SubModuleEnum.ImportPrograms;
    } else {
      this.subUploadBulkData = this.bulkUploadData.SubUploadBulkData[ActionButtonType.Update];
      this.actionButtonType = SubModuleEnum.UpdatePrograms;
    }
  }
  private CloseBulkUpload() {
    this.$emit('close-bulk-upload');
  }
  private DownloadTemplateFile() {
    Root.emit('show-loader', true);
    FetchData('/FileDownload/ProgramImportFile','', ApiContentType.Excel)
      .then((response: Blob) => {
        Root.emit('show-loader', false);
        this.fileDownload(response, 'ProgramImport.xlsx');
      })
      .catch(() => {
        Root.emit('show-loader', false);
      });

    // Download template
  }

  private GetFileData(event: any) {
    const xlsxfile = event.target.files ? event.target.files[0] : null;
    const fileExtension = xlsxfile.name.substring(xlsxfile.name.lastIndexOf('.'));
    if(fileExtension === this.supportedExtension) {
      this.uploadError = '';
      this.currentInstance = ImportFileService.GetInstance(this.moduleType);
      this.tableHeadings = this.currentInstance.GetTableHeadings(this.clickedButtonType);
      const schema = this.currentInstance.GetSchema();
      const excelHeadings = this.currentInstance.GetExcelHeadings(this.clickedButtonType);
      let isOrderAndCountCorrect = true;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      readXlsxFile(xlsxfile, { schema,transformData(data) {
        isOrderAndCountCorrect = excelHeadings.length === data[0].length && excelHeadings.every((value: string, index: number) => value === data[0][index]);
        return data;
      },
      }).then((data: { rows: any; errors: any }): void => {
        if(!isOrderAndCountCorrect) {
          this.uploadError = this.$t('Import.IncorrectTemplate').toString();
        } else {
          if(data.rows.length !== 0) {
            if(data.rows.length <= this.maxImports) {
              this.importedData = this.currentInstance.GetValues(data.rows,this.actionButtonType);
              const previewData = this.currentInstance.GetParentInstance().GetDataToDisplayPreview(this.importedData);
              const validationErrors: {index: number[]; message: string[]} = this.currentInstance.ValidateImportedData(previewData, data.errors, this.clickedButtonType);
              this.previewData = new PreviewBulkDataViewModel(this.$t('Import.ProgramImport.Heading').toString(),this.$t('Import.ProgramImport.PreviewBulkData.Description').toString(),previewData,this.importedData,this.tableHeadings,validationErrors,this.moduleType,this.actionButtonType);
              this.$emit('show-preview',true,this.previewData);
            } else {
              this.uploadError = this.$t('Import.MaxItems').toString();
            }
          } else {
            this.uploadError = this.$t('Import.EmptyFile').toString();
          }
        }
      }).catch(() => {
        this.uploadError = this.$t('Exception.SomethingWentWrong',{instruction: this.$t('Error.ReadImportInstructionsCarefully')}).toString();
      });
    } else {
      this.uploadError = this.$t('Import.IncorrectFileExtension').toString();
    }
    event.target.value=null;
  }
}
export default toNative(UploadBulkData);
