
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { ProgramTrackItem } from '@/entities/Program/ProgramTrackItem';
import ImageProcess from '@/components/Common/ImageProcess.vue';
import loading from '@/components/Common/loading.vue';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { TrackSignalRModel } from '@/signalRRequest/Program/TrackSignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import colorpicker from '@/components/Common/colorpicker.vue';
import { AppColorSettingsInfo } from '@/entities/Application/AppColorSettingsInfo';
import { FetchData, Root, Store } from '@/main';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { reactive } from 'vue';
@Component({
  components: {
    ImageProcess,
    loading,
    colorpicker,
  },
})
class ProgramTrackItemEditPane extends Vue {
  public showLoading = false;
  public loaderBorderColor?: string = '';
  @Prop() private selectedTrackItem?: ProgramTrackItem;
  @Prop() private applicationborderColor?: string;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private showImageProcess = false;
  private localTrackItem: ProgramTrackItem | null = null;
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private isImage = false; // use for show or hide image or color squre
  private state = reactive({
    localTrackItem: null as ProgramTrackItem | null,
  });

  private rules = {
    localTrackItem: {
      Name: {
        required,
        minLength: minLength(1),
      },
    },
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);
  private created() {
    if (this.selectedTrackItem != null) {
      this.localTrackItem = ProgramTrackItem.createEmpty();
      // assign current image url to old image url property for cancel operation
      if (
        this.selectedTrackItem.OldIconUrl === '' ||
        this.selectedTrackItem.OldIconUrl == null
      ) {
        this.selectedTrackItem.OldIconUrl = this.selectedTrackItem.IconUrl;
        this.selectedTrackItem.OldIconUrlOriginal = this.selectedTrackItem.IconUrlOriginal;
        this.selectedTrackItem.OldName = this.selectedTrackItem.Name;
      }

      this.localTrackItem = Object.assign(
        this.localTrackItem,
        this.selectedTrackItem,
      );
      if ( this.localTrackItem.IconUrl !== null && this.localTrackItem.TrackColor !== '' ) {
        // check image/icon is available or not
        this.isImage = true;
      }
      if (this.localTrackItem.TrackColor === '') {
        // trackColor null set by default value that is top nav color
        const appDefaultColorsInfo: AppColorSettingsInfo = this.storeHelper.GetApplicationColorSettingsFromStore();
        this.localTrackItem.TrackColor = appDefaultColorsInfo.topNavBackgroundColor;
      }
      this.state.localTrackItem = this.localTrackItem;
    }
  }
  private Cancel(val: any) {
    val.$reset();
    // update initial values to selected track item for cancel operation
    this.selectedTrackItem!.IconUrl = this.selectedTrackItem!.OldIconUrl!;
    this.selectedTrackItem!.IconUrlOriginal = this.selectedTrackItem!.OldIconUrlOriginal!;
    this.selectedTrackItem!.Name = this.selectedTrackItem!.OldName!;
    this.$emit('program-track-item-cancel-operation', this.selectedTrackItem);
  }
  private SendNotificationRequest(): SignalRModelRequest {
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    const signalR: TrackSignalRModel = new TrackSignalRModel(
      Root,
      Store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();

    if (this.localTrackItem!.Id === 0) {
      signalObject = signalR.SingleCreateTrackItemSignalRModelRequest;
    } else {
      signalObject = signalR.SingleUpdateTrackItemSignalRModelRequest;
      const arr: number[] = [];
      arr.push(this.localTrackItem!.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
    }).toString();

    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({selectedName: this.localTrackItem!.Name});
    signalR.AddToNotificationPopup(signalObject);
    return signalObject;
  }

  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    programTrackItem: ProgramTrackItem,
  ) {
    const signalR: TrackSignalRModel = new TrackSignalRModel(
      Root,
      Store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(programTrackItem.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = signalR.GetTrackItemSendToSignalR(programTrackItem);
    signalR.PerformTrackSingleOperation(signalObject);
  }
  private async Submit(val: any) {
    val.$touch();
    const res = !await val.$validate();
    if(res) {
      return;
    }
    val.$reset();
    // // No errors submitting.
    this.showLoading = true;
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    const signalObject = this.SendNotificationRequest();
    FetchData('/Program/SaveOrUpdateProgramTrack',
      JSON.stringify(this.localTrackItem))
      .then((response) => {
        if (response.ValidationErrors.length > 0) {
          // Server side validation failuere
          alert(this.$t(response.ValidationErrors[0]));
          this.showLoading = false;
        } else {
          // Successful update / save
          this.$emit(
            'program-track-item-edit-operation',
            response.TrackItem,
          );
          Root.emit(
            'program-track-item-edit-operation',
            response.TrackItem,
          );
          Root.emit('reload-programs-data');
          this.SendNotificationRequestToQueue(
            signalObject,
            response.TrackItem as ProgramTrackItem,
          );
        }
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  private GetImageUrl() {
    return this.localTrackItem!.IconUrl === null
      ? this.missingImage
      : this.localTrackItem!.IconUrl === ''
        ? this.missingImage
        : this.localTrackItem!.IconUrl;
  }
  private clickOnImage(val: boolean) {
    this.selectedTrackItem!.Name = this.localTrackItem!.Name;
    this.showImageProcess = val;
    this.$emit('program-track-item-imageUpload-operation', val);
  }
  private SetImageIconUrl(iconUrl: string, iconUrlOriginal: string) {
    this.localTrackItem!.IconUrl = iconUrl;
    this.localTrackItem!.IconUrlOriginal = iconUrlOriginal;
    this.$emit('program-track-item-imageUpload-completed', this.localTrackItem);
  }
  private getPlaceholder(): string {
    return this.$t('Track.Placeholder').toString();
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private SetForegroundcolor(color: string, oldColor: string) {
    // old color will be empty if component first time loaded
    this.localTrackItem!.TrackColor = color.trim();
    this.GetColor();
  }
  private GetColor() {
    // for set color of color square
    return {
      background:  this.localTrackItem!.TrackColor,
      border: '2px solid #525252',
    };
  }
}
export default toNative(ProgramTrackItemEditPane);
