/* eslint-disable max-len */
import { BaseSignalRModel } from '../BaseSignalRModel';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import EmitEnum from '@/enums/EmitEnums';
import { SignalRModelRequest } from '../SignalRModelRequest';
import { AppRequestInfo } from '@/entities/AppRequestInfo';

import { ProgramSessionQuestionsItem, ProgramSessionQuestionsItemSignalR } from '@/entities/Program/ProgramSessionQuestionsItem';
import NotificationStatusEnum from '@/enums/NotificationStatusEnum';
import { Root, app } from '@/main';

const ProgramSessionQuestionsSignalRModelRequestIds = {
  BulkDelete: 1,
  BulkAssignSelectedProgramIds: 2,
  SingleCreate: 3,
  SingleEdit: 4,
  SingleDelete: 5,
};

export class ProgramSessionQuestionsSignalRModel extends BaseSignalRModel {
  // Get SignalRModelRequest for deleting seleted session questions in a batch
  get DeleteSelectedSessionQuestionsSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSessionQuestionsSignalRModelRequestIds.BulkDelete);
  }
  // Get SignalRModelRequest for updating selected program ids with session questions
  get BulkAssignSelectedProgramIdsSignalRModelRequest(): SignalRModelRequest {

    return this.GetSelectedSignalRModelRequestByID(ProgramSessionQuestionsSignalRModelRequestIds.BulkAssignSelectedProgramIds);
  }

  // Get SignalRModelRequest for adding a single program  session question
  get SingleCreateSessionQuestionSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSessionQuestionsSignalRModelRequestIds.SingleCreate);
  }
  // Get SignalRModelRequest for Updating a single session question
  get SingleUpdateSessionQuestionSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSessionQuestionsSignalRModelRequestIds.SingleEdit);
  }
  // Get SignalRModelRequest for Deleting a single program session question
  get SingleDeleteSessionQuestionSignalRModelRequest(): SignalRModelRequest {
    return this.GetSelectedSignalRModelRequestByID(ProgramSessionQuestionsSignalRModelRequestIds.SingleDelete);
  }

  readonly SessionQuestionsBatchOperationURL = '/BulkOperations/PerformBatchOperation';
  readonly SessionQuestionsPostToClientsSignalRURL = '/BulkOperations/PostToClientsSignalR';

  constructor(public rootObj: any, public $store: any, public $t: any) {
    super(rootObj, $store);
    const reqInfo: AppRequestInfo = this.GetRequestInfoFromStore();
    const loggedInUserFullName = reqInfo.FirstName + ' ' + reqInfo.LastName;
    const signalRAPIProgramURL = app.config.globalProperties.$SignalRBaseURL + 'api/SignalRProgram/PushProgramSignalRChanges';

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSessionQuestionsSignalRModelRequestIds.BulkDelete, ModuleEnum.ProgramSessionQuestions, SubModuleEnum.Delete, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSessionQuestionsSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteProgramSessionQuestions', 'Notifications.DeletedProgramSessionQuestions', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionSessionsQuestionsDeleted'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSessionQuestionsSignalRModelRequestIds.BulkAssignSelectedProgramIds, ModuleEnum.ProgramSessionQuestions, SubModuleEnum.EditSelectedProgramIds,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSessionQuestionsSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.EditOperation', '', 'Notifications.AssigningProgramSessionQuestionItemSelectedProgramIds', 'Notifications.AssignedProgramSessionQuestionItemSelectedProgramIds', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionSessionQuestionAssignSelectedProgramIds'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSessionQuestionsSignalRModelRequestIds.SingleCreate, ModuleEnum.ProgramSessionQuestions, SubModuleEnum.SingleCreate,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSessionQuestionsSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.SaveOperation', '', 'Notifications.SavingProgramSessionQuestionItem', 'Notifications.SavedProgramSessionQuestionItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleSessionQuestion'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSessionQuestionsSignalRModelRequestIds.SingleEdit, ModuleEnum.ProgramSessionQuestions, SubModuleEnum.SingleEdit, reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSessionQuestionsSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.UpdateOperation', '', 'Notifications.UpdatingProgramSessionQuestionItem', 'Notifications.UpdatedProgramSessionQuestionItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleSessionQuestion'));

    this.arrSignalRModelRequests.push(new SignalRModelRequest(ProgramSessionQuestionsSignalRModelRequestIds.SingleDelete, ModuleEnum.ProgramSessionQuestions, SubModuleEnum.SingleDelete,  reqInfo.ApplicationId, reqInfo.ApplicationInstanceId, ProgramSessionQuestionsSignalRModel.newGuid(), reqInfo.Username, loggedInUserFullName, reqInfo.AdminType, signalRAPIProgramURL, [], 'Notifications.DeleteOperation', '', 'Notifications.DeleteSingleSessionQuestionItem', 'Notifications.DeletedSingleSessionQuestionItem', false, null,'', false, new Date(), '', NotificationStatusEnum.Sending, '', 'ActionUpdatingSingleSessionQuestion'));
  }
  // Perform Session Questions Batch Operations
  public PerformSessionQuestionBatchOperation(signalRModelRequest: SignalRModelRequest) {

    // disable Session Questions
    if (signalRModelRequest.SelectedIds.length > 0) {
      this.DisableSessionQuestions(signalRModelRequest);
    }
    this.SendSignalRModelRequestToHub(signalRModelRequest, this.SessionQuestionsBatchOperationURL);
  }


  // Perform Session Questions Single Operations
  public PerformSessionSingleOperation(signalRModelRequest: SignalRModelRequest) {
    // disable session Questions
    if (signalRModelRequest.SelectedIds.length > 0) {
      if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate) {
        this.DisableSessionQuestions(signalRModelRequest);
      }
    }


    this.SendSignalRModelRequestToHubWithoutAddingToQueue(signalRModelRequest, this.SessionQuestionsPostToClientsSignalRURL);


    this.SendSignalRModelRequestToQueueWithoutEmit(signalRModelRequest, this.SessionQuestionsBatchOperationURL);

  }

  // Method that fires when Program SignalR response model is received from Hub
  public ProgramSignalRModelResponseReceived(signalRModelRequest: SignalRModelRequest) {
    this.SignalRModelResponseReceived(signalRModelRequest);
    // enable Session Questions

    if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate && signalRModelRequest.SubModule !== SubModuleEnum.SingleDelete) {
      this.EnableSessionQuestions(signalRModelRequest);
    }
  }

  // Disable Session Questions and add to Notification Popup that an operation has started
  public DisableSessionQuestionsAndAddToNotificationPopup(signalRModelRequest: SignalRModelRequest) {
    if (signalRModelRequest.SubModule !== SubModuleEnum.SingleCreate) {
      this.DisableSessionQuestions(signalRModelRequest);
    }
    this.EmitAddNewNotifications(signalRModelRequest);
  }

  // Get Session Question Item to send to SignalR as JSON data
  public GetSessionQuestionItemToSendToSignalR(sessionQuestion: ProgramSessionQuestionsItem): string {

    const item: ProgramSessionQuestionsItemSignalR = ProgramSessionQuestionsItemSignalR.GetProgramSessionQuestionsItemSignalR(sessionQuestion);
    return JSON.stringify(item);
  }

  // Update Session Question in array based on Index for data received from Signal R
  public FillSessionQuestionObjectFromSignalRResponse(
    sessionQuestion: ProgramSessionQuestionsItemSignalR,
    objIndex: number,
    sessionQuestionsList: ProgramSessionQuestionsItem[],
  ): ProgramSessionQuestionsItem {
    const question = this.GetSessionQuestion(sessionQuestionsList[objIndex], sessionQuestion);
    return question;
  }


  // Create new Session Question Item based on Response received from SignalR
  public FillProgramSessionQuestionObjectFromSignalRResponseNewItem(
    sessionQuestion: ProgramSessionQuestionsItemSignalR,
  ): ProgramSessionQuestionsItem {
    const question = this.GetSessionQuestion(ProgramSessionQuestionsItem.createEmpty(), sessionQuestion);
    return question;
  }

  // Disable Selected Session Questions based on sessionQuestion Ids
  private DisableSessionQuestions(signalRModelRequest: SignalRModelRequest) {
    Root.emit(EmitEnum.disableProgramSessionQuestionsItems, signalRModelRequest.SelectedIds);
  }

  // Enable Selected Session Questions based on sessionQuestion Ids
  private EnableSessionQuestions(signalRModelRequest: SignalRModelRequest) {
    Root.emit(EmitEnum.enableProgramSessionQuestionsItems, signalRModelRequest.SelectedIds);
  }

  // Get Program Item from ProgramSignalR Item

  private GetSessionQuestion(sessionItem: ProgramSessionQuestionsItem, sessionQuestion: ProgramSessionQuestionsItemSignalR): ProgramSessionQuestionsItem {

    sessionItem = ProgramSessionQuestionsItem.GetSessionQuestionItemFromSessionQuestionItemSignalR(sessionItem, sessionQuestion);
    return sessionItem;
  }


}
