
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { ProgramTrackItem } from '@/entities/Program/ProgramTrackItem';
import ProgramTrackItemEditPane from '@/components/Program/ProgramDetail/Tracks/ProgramTrackEditItemPane.vue';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { TrackSignalRModel } from '@/signalRRequest/Program/TrackSignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { AppColorSettingsInfo } from '@/entities/Application/AppColorSettingsInfo';
import { FetchData, Root, Store } from '@/main';
@Component({
  components: {
    ProgramTrackItemEditPane,
    loading,
    ModalPopup,
  },
})
class ProgramTrackListViewEditItemPane extends Vue {
  @Prop() private selectedEditTrackItem?: ProgramTrackItem;
  @Prop() private applicationborderColor?: string;
  private localTrackItem: ProgramTrackItem | null = null;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private showEditTrack?: boolean = false;
  private loaderBorderColor = '';
  private showLoading = false;
  private isMobileView = false;
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent: string = this.getRemoveTrackMessage();
  private trackIdTobeDeleted = 0;
  private isImage = false; // use for show or hide image or color square section
  private trackBackgroundColor = ''; // set trackcolor
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private deleteIcon = require('@/assets/Images/Delete-icon.svg');

  @Watch('selectedEditTrackItem')
  private onchange(val: ProgramTrackItem) {
    if (val.Id > 0) {
      this.localTrackItem!.Name = val.Name;
      this.localTrackItem!.IconUrl = val.IconUrl;
      this.localTrackItem!.IconUrlOriginal = val.IconUrlOriginal;
      // if icon is available
      if (val.IconUrl !== null) {
        this.isImage = true;
      }
    }
  }
  private mounted() {
    // check icon available or not for selected item
    if ( this.selectedEditTrackItem!.IconUrl !== null ) {
      this.isImage = true;
    }
    if (this.selectedEditTrackItem != null) {
      this.localTrackItem = this.selectedEditTrackItem;
    }
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    const self = this;
    Root.on(
      'program-track-item-imageupdated-operation',
      (pt: ProgramTrackItem) => {
        if ( pt.IconUrl !== null ) {
          this.isImage = true;
        }
        if (pt != null) {
          if (pt.Id === self.localTrackItem!.Id) {
            self.selectItem(pt);
          }
        }
      },
    );
    // check for ipad and mobile view
    if (window.innerWidth <= 1300) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }

    Root.on('program-track-close-others-operation', () => {
      this.showEditTrack = false;
    });
  }

  private GetImageUrl(imgurl: string) {
    this.isImage = true;
    if (imgurl === null || imgurl === '') {
      this.isImage = false;
      return this.missingImage;
    }
    return imgurl;
  }
  private getEditButtonstyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        width: 'auto',
      };
    } else {
      return {};
    }
  }
  private getLeftDivStyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        width: '60%',
      };
    } else {
      return { width: '70%' };
    }
  }
  private getRightDivStyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        width: '60%',
      };
    } else {
      return { width: '30%' };
    }
  }
  private getDeleteButtonstyle(isMobileview: boolean) {
    if (isMobileview) {
      return {
        'margin-left': '5px',
        'height': 'auto',
        'width': 'auto',
      };
    } else {
      return { 'margin-left': '15px' };
    }
  }
  private programTrackItemImageEditedOperation(val: ProgramTrackItem) {
    this.selectItem(val);
  }
  private selectItem(val: ProgramTrackItem) {
    Root.emit('program-track-close-others-operation');
    const selectedItem = val;
    this.localTrackItem = selectedItem;
    this.showEditTrack = true;
    this.$emit('program-track-item-edit-operation', selectedItem);
    this.scrollToElement();
  }
  private programTrackItemCancelOperation(pt: ProgramTrackItem) {
    this.localTrackItem = pt;
    this.showEditTrack = false;
  }

  private programTrackItemEditedOperation(pt: ProgramTrackItem) {
    this.localTrackItem = pt;
    this.showEditTrack = false;
  }

  private programTrackItemImageUploadOperation(val: boolean) {
    this.$emit('program-track-item-imageUpload-operation', val);
  }

  // Remove Track with modal popup
  private getRemoveTrackMessage(): string {
    return this.$t('Track.Delete.Message').toString();
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      this.Delete(this.trackIdTobeDeleted);
    } else {
      return;
    }
  }
  private DeleteSelectedTrack(track: ProgramTrackItem) {
    this.trackIdTobeDeleted = track.Id;
    this.modalPopupVisible = true;
  }
  private SendNotificationRequest(): SignalRModelRequest {
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    const signalR: TrackSignalRModel = new TrackSignalRModel(
      Root,
      Store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    signalObject = signalR.SingleDeleteTrackItemSignalRModelRequest;
    const arr: number[] = [];
    arr.push(this.localTrackItem!.Id);
    signalObject.SelectedIds = arr;
    signalObject.Description = this.$t(signalObject.SubmitMessage, {
      selectedCount: arr.length, // Just single item delete
      user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
    }).toString();
    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalR.AddToNotificationPopup(signalObject);
    return signalObject;
  }
  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    programTrackItem: ProgramTrackItem,
  ) {
    const signalR: TrackSignalRModel = new TrackSignalRModel(
      Root,
      Store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(programTrackItem.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = signalR.GetTrackItemSendToSignalR(programTrackItem);
    signalR.PerformTrackSingleOperation(signalObject);
  }
  private Delete(trackId: number) {
    const signalObject = this.SendNotificationRequest();
    FetchData('/Program/DeleteProgramTrack', JSON.stringify({
      id: trackId,
    }))
      .then((response) => {
        if (response.success === false) {
          // Server side validation failuere
          alert(this.$t('Message.UnableToDelete'));
        } else {
          // Successful delete
          this.SendNotificationRequestToQueue(
            signalObject,
            this.localTrackItem!,
          );
          // Successful update / save
          Root.emit('program-track-item-delete-operation', trackId);
        }
      })
      .catch(() => {
        // Log the error when required
      });
  }
  // Scroll to particular element when come back from image process component
  private scrollToElement() {
    setTimeout(() => {
      const el = document.getElementById('programTrackItemEditPane');
      el!.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
        inline: 'nearest',
      });
    }, 100);
  }
  private GetColor() {
    // set track color
    this.trackBackgroundColor = this.localTrackItem!.TrackColor;
    // if trackColor null then pick top nav color that is buy default
    if (this.localTrackItem!.TrackColor == null || this.localTrackItem!.TrackColor === '') {
      const appDefaultColorsInfo: AppColorSettingsInfo = this.storeHelper.GetApplicationColorSettingsFromStore();
      this.trackBackgroundColor = appDefaultColorsInfo.topNavBackgroundColor;
    }
    return {
      background:  this.trackBackgroundColor,
      border: '2px solid #525252',
    };
  }
}
export default toNative(ProgramTrackListViewEditItemPane);
