
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { MultimediaItem } from '@/entities/Program/MultimediaItem';
import ProgramMultimediaEditItemPane from '@/components/Program/ProgramDetail/Multimedia/ProgramMultimediaEditItemPane.vue';
import ProgramMultimediaListAndEditItemView from '@/components/Program/ProgramDetail/Multimedia/ProgramMultimediaListAndEditItemView.vue';
import { Root } from '@/main';

@Component({
  components: {
    ProgramMultimediaEditItemPane,
    ProgramMultimediaListAndEditItemView,
  },
})
class MultimediaListView extends Vue {
  @Prop() private programMedias?: MultimediaItem[];
  @Prop() private selectedProgramId?: number;
  private localMultimedia?: MultimediaItem[] = [];
  private localMultimediaLoading?: boolean = false;
  private loaderBorderColor = '';
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private loadingImage: string = require('@/assets/Images/loading.png');
  private showFileUploadProcess = false;
  private localMultimediaItem: MultimediaItem | null = null;
  private currentEditMultimediaItem: MultimediaItem | null = null;

  private ClosePreviouslyOpenMultimediaForEdit() {
    Root.emit('program-multimedia-close-others-operation', true);
  }

  private AddMultimediaItem() {
    this.ClosePreviouslyOpenMultimediaForEdit();
    const newItem = MultimediaItem.createEmpty();
    newItem.ProgramId = this.selectedProgramId!;
    this.localMultimediaItem = newItem;
    const el = document.querySelector('#dvMultimediaListing');
    if (el) {
      this.scrollToBottom(el);
    }
  }
  private setCurrentlocalItem(multimedia: MultimediaItem) {
    this.currentEditMultimediaItem = multimedia;
    this.currentEditMultimediaItem.ProgramId = this.selectedProgramId!;
  }
  private clickOnImage(val: boolean) {
    this.showFileUploadProcess = val;

    if (this.currentEditMultimediaItem != null) {
      this.$emit('program-multimedia-item-showImageComponent-operation', {
        showFileUploadProcess: val,
        selectedMultimediaitem: this.currentEditMultimediaItem,
      });
    } else {
      this.$emit('program-multimedia-item-showImageComponent-operation', {
        showFileUploadProcess: val,
        selectedMultimediaitem: this.localMultimediaItem,
      });
    }
  }
  private programMultimediaItemCancelOperation() {
    this.localMultimediaItem = null;
    this.currentEditMultimediaItem = null;
  }

  private programMultimediaItemEditedOperation() {
    this.localMultimediaItem = null;
    this.currentEditMultimediaItem = null;
  }

  private mounted() {
    if (this.programMedias != null) {
      this.localMultimedia = this.programMedias;
    }
    Root.on('programDetailLoading', (loading: boolean) => {
      this.localMultimediaLoading = loading;
    });
    Root.on('multimediaData', (multimedias: MultimediaItem[]) => {
      // make default list for new program item
      this.localMultimediaItem = null;
      this.localMultimedia = multimedias;
    });
    Root.on('clearMultimedia', () => {
      this.localMultimedia = [];
    });
    Root.on(
      'program-multimedia-item-imageupdated-operation',
      (multimedia: any) => {
        this.showFileUploadProcess = false;
        const selectedItem = multimedia.selectedMultimediaitem;
        if (selectedItem.Id === 0) {
          this.localMultimediaItem = selectedItem;
        } else {
          this.currentEditMultimediaItem = selectedItem;
        }
        const el = document.querySelector('#dvMultimediaListing');
        if (el) {
          this.scrollToBottom(el);
        }
      },
    );

    Root.on('program-multimedia-close-others-operation', () => {
      this.currentEditMultimediaItem = null;
      this.localMultimediaItem = null;
    });
  }
  private GetImageUrl(item: MultimediaItem) {
    const multiMediaImageUrl = this.localMultimedia!.find(
      (x) => x.Id === item.Id,
    )!.ThumbUrl;
    if (multiMediaImageUrl !== null) {
      return multiMediaImageUrl;
    }
    return this.missingImage;
  }

  private scrollToBottom(el: Element) {
    setTimeout(() => {
      el.scrollTop = el.scrollHeight;
      el.scrollIntoView();
    }, 100);
  }
}
export default toNative(MultimediaListView);
