
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import { Root } from '@/main';
@Component
class ProgramLinkedInformationListView extends Vue {
  @Prop() private linkedInformation?: CustomCategoryItem[];
  @Prop() private selectedProgramId?: number;
  private localLinkedInformation?: CustomCategoryItem[] = [];
  private localAllInformations?: CustomCategoryItem[] = [];
  private localLinkedInformationLoading?: boolean = false;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private loadingImage: string = require('@/assets/Images/loading.png');
  private customCategoryFolders?: CustomCategoryFolder[] = [];

  private mounted() {
    if (this.linkedInformation != null) {
      this.localLinkedInformation = [];
      this.localAllInformations = [];
      this.linkedInformation.forEach((element) => {
        if (element.IsLinked) {
          this.localLinkedInformation!.push(element);
        }
        this.localAllInformations!.push(element);
      });
    }
    Root.on('programDetailLoading', (loading: boolean) => {
      this.localLinkedInformationLoading = loading;
    });
    Root.on(
      'linkedInformationData',
      (customCategory: CustomCategoryItem[]) => {
        this.localLinkedInformation = [];
        this.localAllInformations = [];
        if (customCategory !== undefined && customCategory !== null ) {
          customCategory.forEach((element) => {
            if (element.IsLinked) {
              this.localLinkedInformation!.push(element);
            }
            this.localAllInformations!.push(element);
          });
        }
      },
    );
    Root.on('clearInformations', () => {
      this.localLinkedInformation = [];
      this.localAllInformations = [];
    });
  }
  private GetImageUrl(item: CustomCategoryItem) {
    const customCategoryImageUrl = this.localLinkedInformation!.find(
      (x) => x.CustomCategoryId === item.CustomCategoryId,
    )!.ThumbUrl;
    if (customCategoryImageUrl !== null) {
      return customCategoryImageUrl;
    }
    return this.missingImage;
  }
  private clickOnAddInformation() {
    this.$emit('showAttachInformation', {
      showAddInformation: true,
      informations: this.localAllInformations,
    });
  }
}
export default toNative(ProgramLinkedInformationListView);
