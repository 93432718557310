
import { Component, toNative, Vue } from 'vue-facing-decorator';
import loading from '@/components/Common/loading.vue';
import { FetchData } from '@/main';
import useVuelidate from '@vuelidate/core';
import { required, minLength, email } from '@vuelidate/validators';
import { reactive } from 'vue';
@Component({
  components: {
    loading,
  },
})
class EvaluationQuestionExportResultsToEmailModalPopup extends Vue {
  private message = '';
  // private userEmail = '';
  private showLoading = false;
  private loaderBorderColor = '';
  private evaluationReportSent = false;
  private state = reactive({
    userEmail: '',
  });

  private rules = {
    userEmail: {
      required,
      email,
      minLength: minLength(1),
    },
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);
  private Cancel() {
    this.$emit('confirm-click', {
      clickedYes: false,
    });
  }

  private async clickYes(val: any) {
    val.$touch();
    if(!await val.$validate()) {
      return;
    }
    val.$reset();
    this.showLoading = true;
    FetchData('/Program/SendEvaluationResult', JSON.stringify({
      emailAddress: this.state.userEmail,
    }))
      .then((response) => {
        if (response.success === false) {
          // Server side validation failuere
          alert(this.$t('Message.SomeErrorOccured'));
        } else {
          this.evaluationReportSent = true;
          // Successful update / save
          this.message = this.$t(
            'EvaluationQuestions.Message.EvaluationReport',
            {
              userEmail: this.state.userEmail,
            },
          ).toString();
        }
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
}
export default toNative(EvaluationQuestionExportResultsToEmailModalPopup);
