/* eslint-disable max-len */

import { ProgramEvaluationQuestionsSignalRModel } from '@/signalRRequest/Program/ProgramEvaluationQuestionsSignalRModel';
import { BaseBulkOperationsModel } from '../BaseBulkOperationsModel';
export class EvaluationBulkOperations extends BaseBulkOperationsModel {
  constructor(rootObj: any, $store: any, $t: any) {
    super();

    const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(rootObj, $store, $t);

    this.arrBulkOperations.push({ Id: 1, Name: 'Delete', IconUrl: 'fa fa-trash-o font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Permanently delete your selected items', ConfirmationMessage: 'Notifications.SureDeleteEvaluationItemsHeader1', ConfirmationMessageSubTitle: 'Notifications.SureDeleteEvaluationItemsHeader2', objModel: signalR.DeleteSelectedEvaluationQuestionsSignalRModelRequest, ShowConfirmationPopup: true  });

    this.arrBulkOperations.push({ Id: 2, Name: 'Add to Program', IconUrl: 'fa fa-calendar-check-o font-awesome-icon', IconPadding: 'padding:17px 17px 17px 0px;', Description: 'Assign selected items to evaluation in your program', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkAssignSelectedProgramIdsSignalRModelRequest, ShowConfirmationPopup: false  });

    this.arrBulkOperations.push({ Id: 3, Name: 'Make Anonymous', IconUrl: 'fa fa-eye-slash font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Change selected items to anonymous response', ConfirmationMessage: 'Notifications.SureEvaluationResponsesAnonymousHeader1', ConfirmationMessageSubTitle: 'Notifications.SureEvaluationResponsesAnonymousHeader2', objModel: signalR.BulkMakeAnonymousSignalRModelRequest, ShowConfirmationPopup: true  });

    this.arrBulkOperations.push({ Id: 4, Name: 'Make Not Anonymous', IconUrl: 'fa fa-eye font-awesome-icon', IconPadding: 'padding:17px 17px 17px 0px;', Description: 'Change selected items to non-anonymous response', ConfirmationMessage: 'Notifications.SureEvaluationResponsesNotAnonymousHeader1', ConfirmationMessageSubTitle: 'Notifications.SureEvaluationResponsesNotAnonymousHeader2', objModel: signalR.BulkMakeNotAnonymousSignalRModelRequest, ShowConfirmationPopup: true  });
  }
}
