
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
@Component({})
class SessionChat extends Vue {
  @Prop() private isCheckboxVisible!: boolean;
  @Prop() private moduleName!: string;
  @Prop() private isCheckboxChecked!: boolean;
  private localIsCheckboxChecked = false;
  private mounted() {
    this.localIsCheckboxChecked = this.isCheckboxChecked;
  }
  private GetCheckboxLabelText() {
    return this.$t('Program.SessionChat.EnableSessionChat',{item: this.moduleName});
  }
  private SetCheckboxValue() {
    this.localIsCheckboxChecked = (this.$refs.chkEventVisibility as HTMLInputElement).checked;
    this.$emit('set-checkbox-value', this.localIsCheckboxChecked);
  }
  @Watch('isCheckboxChecked')
  private CheckChangeInCheckboxValue(val: boolean) {
    this.localIsCheckboxChecked = val;
  }
}
export default toNative(SessionChat);
