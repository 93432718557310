
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { ProgramSignalRModel } from '@/signalRRequest/Program/ProgramSignalRModel';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { StoreHelper } from '@/store/StoreHelper';
import { DateTimeOptions } from 'vue-i18n';
import { Root, Store } from '@/main';
@Component({
  components: {
    ModalPopup,
    ValidationSummary,
  },
})
class ProgramBatchDateTime extends Vue {
  @Prop() private selectedItems?: ProgramItem[];
  @Prop() private selectedBulkOperationModel?: SignalRModelRequest;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  // modal popup
  private modalPopupContent = '';
  private modalBodyContent = '';
  private modalPopupVisible = false;
  private showCloseButton = true;
  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private limitedSelectedProgramItems?: any = [];
  private localDateTime: Date = new Date();
  private days = 0;
  private hours = 0;
  private minutes = 0;
  private totalMinutes = 0;
  private moreItemsCount = 0;
  private signalR: ProgramSignalRModel = new ProgramSignalRModel(
    Root,
    Store,
    this.$t,
  );
  // Date format to show for a selected Date time
  private dateOptions: DateTimeOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'UTC',
  };
  // Create a new Date format from dateoptions specified
  private dateFormat = new Intl.DateTimeFormat();
  private storeHelper: StoreHelper = new StoreHelper(Store);
  // read only properties
  private readonly programItemSize = 2;

  private CalculateMinutes(): number {
    this.totalMinutes =
      Number(this.days) * 24 * 60 +
      Number(this.hours) * 60 +
      Number(this.minutes);
    return this.totalMinutes;
  }

  private Cancel() {
    this.$emit('selectBack');
  }
  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  private OnDaysChange(e: any) {
    if (/^(\-?|\+?)\d*$/.test(String(e.target.value))) {
      // Do nothing
    } else {
      this.hours = 0;
      return false;
    }
    this.UpdateDateTime();
  }
  private OnHoursChange(e: any) {
    if (/^(\-?|\+?)\d*$/.test(String(e.target.value))) {
      // do nothing
    } else {
      this.hours = 0;
      return false;
    }
    this.UpdateDateTime();
  }
  private OnMinutesChange(e: any) {
    if (/^(\-?|\+?)\d*$/.test(String(e.target.value))) {
      // Do nothing
    } else {
      this.minutes = 0;
      return false;
    }
    this.UpdateDateTime();
  }

  private UpdateDateTime() {
    const self = this;
    this.limitedSelectedProgramItems.forEach((element: any) => {
      const item = self.selectedItems!.find((x) => x.Id === element.Id);
      if (item !== null) {
        element.StartDate = new Date(item!.StartDate);
        element.StartDate.setMinutes(
          element.StartDate.getMinutes() + this.CalculateMinutes(),
        );
      }
    });
  }
  // UI formatting of the Date value provided
  private FormatDateTime(pi: ProgramItem): string {
    return this.dateFormat
      .format(pi.StartDate)
      .toString()
      .replace(/,/, '')
      .replace(/,/, '   ');
  }

  private MoreItemsString(): string {
    return this.$t('Program.Batch.MoreItems', {selectedCount: this.moreItemsCount}).toString();

  }
  private created() {
    const self = this;
    if (this.selectedItems!.length > this.programItemSize) {
      this.moreItemsCount = this.selectedItems!.length - this.programItemSize;
    }
    this.selectedItems!.every((element, index) => {
      if (index > self.programItemSize - 1) {
        return false;
      } else {
        const item = {
          Id: element.Id,
          ImageUrl: element.ImageUrl,
          Title: element.Title,
          StartDate: element.StartDate,
          Track: element.Track,
        };
        self.limitedSelectedProgramItems.push(item);
        return true;
      }
    });
    this.limitedSelectedProgramItems = this.SortAndGroupProgramItems(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.limitedSelectedProgramItems,
    );
  }
  private mounted() {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    this.dateFormat = new Intl.DateTimeFormat(
      browsersettings.locale,
      this.dateOptions,
    );
    this.localSelectedBulkOperationModel = Object.assign(
      {},
      this.selectedBulkOperationModel,
    );
  }
  private checkForEmpty(value: number) {
    if (value.toString() === '0' || value.toString() === '') {
      return true;
    } else {
      return false;
    }
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      this.SaveDateTime();
    }
  }

  private SaveDateTime() {
    const selectedProgramIds: number[] = this.signalR.GetSelectedProgramIdsToSendInJson(
      this.selectedItems!,
    );
    this.localSelectedBulkOperationModel.SelectedIds = selectedProgramIds;
    this.localSelectedBulkOperationModel.JSONData = JSON.stringify({
      minutes: this.totalMinutes,
    });
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    this.localSelectedBulkOperationModel.Heading = this.$t(
      this.localSelectedBulkOperationModel.Heading,
    ).toString();
    this.localSelectedBulkOperationModel.Description = this.$t(
      this.localSelectedBulkOperationModel.SubmitMessage,
      {
        selectedCount: this.selectedItems!.length,
        user: this.signalR.GetUserName(
          this.localSelectedBulkOperationModel,
          this.$t('UserName.You').toString(),
        ),
      },
    ).toString();
    this.signalR.PerformProgramBatchOperation(
      this.localSelectedBulkOperationModel,
    );
    Root.emit('program-uncheck-select-all');
    Root.emit('empty-track-tag');
  }

  private Save() {
    if (
      this.checkForEmpty(this.days) &&
      this.checkForEmpty(this.hours) &&
      this.checkForEmpty(this.minutes)
    ) {
      this.validationErrorFields = [];
      this.validationErrorFields.push('Please enter valid input.');
      this.visibleValidationSummary = true;
      return false;
    } else {
      this.modalPopupContent = this.$t('EditDateTime.Bulk.Warning').toString();
      this.modalPopupVisible = true;
    }
  }
  private SortAndGroupProgramItems(programs: any[]): any[] {
    programs.sort((a: any, b: any): number => {
      if (a.StartDate > b.StartDate) {
        return 1;
      }
      if (b.StartDate > a.StartDate) {
        return -1;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (new Date(a.StartDate).getTime() === new Date(b.StartDate).getTime()) {
        return a.Title > b.Title ? 1 : a.Title === b.Title ? 0 : -1;
      }
      return 0;
    });
    const sortedPrograms: any[] = [];
    const finalizedTracks: string[] = [];
    for (const p of programs) {
      if (p.Track) {
        // If p is in track, then we need to figure out what other track items to add after p.
        const trackname = p.Track.Name;
        const currentDate = p.StartDate.toDateString();
        if (!finalizedTracks.find((track) => track === trackname + currentDate)) {
          const itemsintrackOndate = programs.filter((p2) => {
            if (p2.Track) {
              if (
                p2.Track.Name === trackname &&
                p2.StartDate.toDateString() === currentDate
              ) {
                return p2;
              }
            }
          });
          for (const item of itemsintrackOndate) {
            sortedPrograms.push(item);
          }
          finalizedTracks.push(String(trackname + currentDate));
        }
      } else {
        // if p i not in track, just add to sorted
        sortedPrograms.push(p);
      }
    }
    return sortedPrograms;
  }
}
export default toNative(ProgramBatchDateTime);
