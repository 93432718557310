import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0117e273"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "right-container-nav" }
const _hoisted_2 = { class: "width25" }
const _hoisted_3 = { class: "width50" }
const _hoisted_4 = { class: "main-heading primary-heading" }
const _hoisted_5 = { class: "margin-top-20" }
const _hoisted_6 = { class: "import-description" }
const _hoisted_7 = { class: "margin-top-20" }
const _hoisted_8 = { class: "padding-left-20 description" }
const _hoisted_9 = { class: "btn btn-primary btn-size-header-main margin-top-20 upload-button" }
const _hoisted_10 = ["accept"]
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "error-vuelidate margin-top-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "btn btn-secondary btn-size-default",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseBulkUpload()))
        }, _toDisplayString(_ctx.$t("CancelButton")), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.bulkUploadData.Heading), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.subUploadBulkData.Description), 1),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("ul", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subUploadBulkData.Instructions, (instruction, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "import-instructions"
            }, [
              _createTextVNode(_toDisplayString(instruction.text) + " ", 1),
              _createElementVNode("span", {
                class: "hyper-link",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.DownloadTemplateFile && _ctx.DownloadTemplateFile(...args)))
              }, _toDisplayString(instruction.link), 1)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("label", _hoisted_9, [
        _createElementVNode("input", {
          type: "file",
          accept: _ctx.supportedExtension,
          hidden: "",
          onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.GetFileData && _ctx.GetFileData(...args)))
        }, null, 40, _hoisted_10),
        _createElementVNode("img", {
          src: _ctx.uploadFileIcon,
          alt: "upload-file",
          class: "margin-right-10"
        }, null, 8, _hoisted_11),
        _createTextVNode(" " + _toDisplayString(_ctx.subUploadBulkData.ButtonText), 1)
      ]),
      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.uploadError), 1)
    ])
  ]))
}