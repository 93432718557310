
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { ProgramSessionQuestionsItem } from '@/entities/Program/ProgramSessionQuestionsItem';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import loading from '@/components/Common/loading.vue';
import { StoreHelper } from '@/store/StoreHelper';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { DateTimeOptions } from 'vue-i18n';
import { FetchData, Root, Store } from '@/main';
import ApiContentType from '@/enums/ApiContentType';
@Component({
  components: {
    loading,
    ModalPopup,
  },
})
class ProgramSessionQuestionListView extends Vue {
  @Prop() private sessionQuestions?: ProgramSessionQuestionsItem[];
  @Prop() private programQuestionLinkExpirationDate?: Date;
  @Prop() private programQuestionPublishedLink?: string;
  @Prop() private selectedProgramId?: number;
  private showCopiedMessage?: boolean = false;
  private localSessionLoading?: boolean = false;
  private localSessionQuestions?: ProgramSessionQuestionsItem[] = [];
  private localprogramQuestionPublishedLink = '';
  private localprogramQuestionLinkExpirationDate = '';
  private excelIcon: string = require('@/assets/Images/Excel.jpeg');
  private loadingImage: string = require('@/assets/Images/loading.png');
  private manageIcon = require('@/assets/Images/three-dot-icon.svg');
  private linkCopiedFadeoutTime = 1500;
  private loaderBorderColor = '';
  private showLoading = false;
  private fileDownload = require('js-file-download');
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private tempSelectedProgramSessionQuestionsItem: ProgramSessionQuestionsItem | null = null;
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private dateFormat = new Intl.DateTimeFormat();
  private dateOptions: DateTimeOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  };
  private mounted() {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, this.dateOptions);
    if (this.sessionQuestions != null) {
      this.localSessionQuestions = this.sessionQuestions;
    }
    if (this.programQuestionLinkExpirationDate != null) {
      this.localprogramQuestionLinkExpirationDate = this.localprogramQuestionLinkExpirationDate = this.dateFormat
        .format(this.programQuestionLinkExpirationDate)
        .toUpperCase();
    }
    if (this.programQuestionPublishedLink != null) {
      this.localprogramQuestionPublishedLink = this.programQuestionPublishedLink;
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Root.on('programDetailLoading', (loading: boolean) => {
      this.localSessionLoading = loading;
    });
    Root.on(
      'sessionQuestionData',
      (sessionQuestions: ProgramSessionQuestionsItem[]) => {
        this.localSessionQuestions = sessionQuestions;
      },
    );
    Root.on(
      'ProgramQuestionLinkExpirationDate',
      (linkExpireDate: Date) => {
        if (linkExpireDate != null) {
          this.localprogramQuestionLinkExpirationDate = this.dateFormat
            .format(linkExpireDate)
            .toUpperCase();
        }
      },
    );
    Root.on('ProgramQuestionPublishedLink', (publishedLink: string) => {
      if (publishedLink != null) {
        this.localprogramQuestionPublishedLink = publishedLink;
      }
    });
    Root.on('clearSessionQuestions', () => {
      this.localprogramQuestionPublishedLink = '';
      this.localprogramQuestionLinkExpirationDate = '';
      this.localSessionQuestions = [];
    });
  }

  private formatDate(pi: ProgramSessionQuestionsItem): string {
    return this.dateFormat.format(pi.ModifiedDate).toUpperCase();
  }
  private formatTime(pi: ProgramSessionQuestionsItem): string {
    return pi.ModifiedDate.toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    });
  }

  // Click on Yes/Cancel Button of Popup
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      this.DelinkProgramQuestionConfirmed(
        this.tempSelectedProgramSessionQuestionsItem!,
      );
    } else {
      return;
    }
  }
  // Export Session Questions Data for a particular Program
  private ExportResultsToExcel() {
    this.showLoading = true;
    FetchData('/FileDownload/ExportProgramQuestion', JSON.stringify({
      programId: this.selectedProgramId,
    }), ApiContentType.Excel)
      .then((response) => {
        this.fileDownload(response, 'ProgramQuestions.xlsx');
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  private DelinkProgramQuestionConfirmed(
    programSessionQuestionItem: ProgramSessionQuestionsItem,
  ) {
    this.modalPopupVisible = false;
    FetchData('/Program/DelinkProgramQuestion', JSON.stringify({
      programQuestionId: programSessionQuestionItem.id,
      programId: this.selectedProgramId,
      updateGlobalProgramEvaluation:
          programSessionQuestionItem.globalProgramQuestion,
    }))
      .then((response) => {
        if (response.success === false) {
          // Server side validation failuere
          alert(this.$t('Message.UnableToRemove'));
        } else {
          // need information send to signalr
          const sessionQuestionItem = {
            id: programSessionQuestionItem.id,
            // programId: this.selectedProgramId,
            // updateGlobalProgramEvaluation:
            //   programSessionQuestionItem.globalProgramQuestion,
            programDetailType: 'SessionQuestions',
          };
          this.$emit('session-question-delink-program-notification', sessionQuestionItem);
        }
      })
      .catch(() => {
        // Log error if required
      });
  }

  private DelinkProgramQuestion(
    programSessionQuestionItem: ProgramSessionQuestionsItem,
  ) {
    this.tempSelectedProgramSessionQuestionsItem = programSessionQuestionItem;
    this.modalPopupVisible = true;
    if (programSessionQuestionItem.globalProgramQuestion) {
      this.modalPopupContent = this.$t(
        'SessionQuestions.RemoveGlobalProgramQuestion',
      ).toString();
    } else {
      this.modalPopupContent = this.$t(
        'SessionQuestions.RemoveProgramQuestion',
      ).toString();
    }
  }

  // Export Session Questions Data for a particular Session Question
  private ExportSingleProgramQuestionResultToExcel(
    programSessionQuestionItem: ProgramSessionQuestionsItem,
  ) {
    this.showLoading = true;
    FetchData('/FileDownload/ExportProgramQuestion', JSON.stringify({
      programQuestionId: programSessionQuestionItem.id,
      programId: this.selectedProgramId,
    }), ApiContentType.Excel)
      .then((response) => {
        this.fileDownload(response, 'ProgramQuestions.xlsx');
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  // Method to copy Link to Clipboard
  private copyPublicLinkToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.localprogramQuestionPublishedLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        this.showCopiedMessage = true;
      } else {
        alert('Oops, unable to copy');
      }
    } catch (err) {
      alert('Oops, unable to copy');
    }
    if (this.showCopiedMessage) {
      setTimeout(() => {
        this.showCopiedMessage = false;
      }, this.linkCopiedFadeoutTime);
    }
    document.body.removeChild(selBox);
  }
}
export default toNative(ProgramSessionQuestionListView);
