
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { ProgramEvaluationQuestionType } from '@/entities/Program/ProgramEvaluationQuestionType';
import { ProgramEvaluationRecommendedQuestions } from '@/entities/Program/ProgramEvaluationRecommendedQuestions';
@Component
class EvaluationQuestionsLandingPage extends Vue {
  @Prop() private programEvaluationQuestionTypes?: ProgramEvaluationQuestionType[];

  private evaluationRecommendedQuestions: ProgramEvaluationRecommendedQuestions[] = ProgramEvaluationRecommendedQuestions.GetRecommendedQuestions();
  private CreateNewEvaluationQuestion(type: number) {
    this.$emit('create-new-question-click', type);
  }
  private evenIndex(index: number): boolean {
    if (index % 2 === 0) {
      return true;
    } else {
      return false;
    }
  }


}
export default toNative(EvaluationQuestionsLandingPage);
