
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import loading from '@/components/Common/loading.vue';
import { SessionCheckInViewModel } from '@/entities/SessionCheckInViewModel';
import { maxValue, minValue, required } from '@vuelidate/validators';
import { FetchData, Root } from '@/main';
import ApiContentType from '@/enums/ApiContentType';
import { reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
@Component({
  components: {
    loading,
  },
})
export default class SessionCheckIn extends Vue {
  @Prop() private sessionCheckInData!: SessionCheckInViewModel;
  private localSessionCheckInData: SessionCheckInViewModel = SessionCheckInViewModel.createEmpty();
  private excelIcon: string = require('@/assets/Images/Excel.jpeg');
  private loadingImage: string = require('@/assets/Images/loading.png');
  private showLoading = false;
  private loaderBorderColor = '';
  private fileDownload = require('js-file-download');
  private areSeatsLimited = false;
  private isDataLoading = false;
  private state = reactive({
    localSessionCheckInData: SessionCheckInViewModel.createEmpty(),
  });

  private rules = {
    localSessionCheckInData: {
      MaxCheckInLimitQuantity: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(100000),
      },
    },
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);

  private mounted() {
    this.localSessionCheckInData = Object.assign({}, this.sessionCheckInData);
    this.state.localSessionCheckInData = this.localSessionCheckInData;
    this.AreSeatsLimited();
    Root.on('programDetailLoading', (isLoading: boolean) => {
      this.isDataLoading = isLoading;
      this.AreSeatsLimited();
    });
    Root.on('clear-session-check-in', (val: SessionCheckInViewModel)=> {
      this.localSessionCheckInData = val;
      this.AreSeatsLimited();
    });
  }
  private AreSeatsLimited() {
    if(this.localSessionCheckInData.MaxCheckInLimitQuantity > 0) {
      this.areSeatsLimited = true;
    } else {
      this.areSeatsLimited = false;
    }
  }
  private ExportResultsToExcel() {
    this.showLoading = true;
    FetchData('/Program/ExportCheckedInParticipantDataToExcel', JSON.stringify({
      programId: this.localSessionCheckInData.Id,
    }), ApiContentType.Excel)
      .then((response) => {
        this.fileDownload(response, 'ParticipantExport.xlsx');
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  private ToggleMaxSeats() {
    if (!this.areSeatsLimited) {
      this.localSessionCheckInData.MaxCheckInLimitQuantity = 1;
    } else {
      this.localSessionCheckInData.MaxCheckInLimitQuantity = -1;
    }
    this.SaveData();
  }
  private SaveData() {
    this.localSessionCheckInData.IsSessionCheckInEnabled = (this.$refs.chkSessionCheckInEnabled as HTMLInputElement).checked;
    this.$emit('change-in-session-check-in', this.localSessionCheckInData);
  }
  private GetCheckedInParticipantCount() {
    if (this.localSessionCheckInData.MaxCheckInLimitQuantity > -1) {
      return  this.localSessionCheckInData.CheckedInParticipantsCount + ' / ' + this.localSessionCheckInData.MaxCheckInLimitQuantity;
    } else {
      return this.localSessionCheckInData.CheckedInParticipantsCount;
    }
  }

  private ValidatePositiveInteger(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = parseInt(input.value, 10);
    this.localSessionCheckInData.MaxCheckInLimitQuantity = Number(value);
  }

  private GetCheckedInParticipantDetails() {
    this.$emit('show-checked-in-participant-details', true);
  }
  @Watch('sessionCheckInData', {deep: true})
  private ChangeInSessionCheckInData(val: SessionCheckInViewModel) {
    this.localSessionCheckInData = val;
    this.state.localSessionCheckInData = this.localSessionCheckInData;
  }
}
