
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator';
import VueTagsInput from '@james090500/vue-tagsinput';
import { Root } from '@/main';
@Component({
  components: {
    VueTagsInput,
  },
})
class InputTag extends Vue {
  @Prop() private selectedTags?: any;
  @Prop() private existingTags?: any;
  private localselectedTags?: any = [];
  private localexistingTags?: any = [];
  private tagPlaceholder = this.getPlaceHolderText();
  private GetselectedTags() {
    this.localselectedTags = this.selectedTags;
  }
  private GetexistingTags() {
    this.localexistingTags = this.existingTags;
  }
  private getPlaceHolderText(): string {
    return this.$t('Tag.SelectOrAdd').toString();
  }
  private mounted() {
    this.GetselectedTags();
    this.GetexistingTags();
    Root.on('clearTags', () => {
      this.localselectedTags = [];
      this.GetexistingTags();
    });
    // update tags
    Root.on('UpdateTags', (tags: any) => {
      this.localselectedTags = [];
      if (tags != null) {
        for (const tag of tags) {
          this.localselectedTags.push({ key: tag.Id, value: tag.TagName });
        }
      }
    });
    Root.on('AppendTags', (tag: any) => {
      if (tag != null) {
        const objIndex = this.localselectedTags.findIndex(
          (obj: any) => obj.key === tag.Id,
        );
        if (objIndex === -1) {
          this.localselectedTags.push({ key: tag.Id, value: tag.TagName });
        }
        this.onTagsUpdated();
      }
    });
    if(this.$refs.vueAttachedTags !== null && this.$refs.vueAttachedTags !== undefined) {
      (this.$refs.vueAttachedTags as any).tags = this.localexistingTags;
    }
  }
  private onTagsUpdated() {
    if(this.localselectedTags !== null && this.localselectedTags !== undefined) {
      if (this.localselectedTags.length === 0) {
        this.tagPlaceholder = this.getPlaceHolderText();
      } else {
        this.tagPlaceholder = '';
      }
      this.$emit('selectedTags', this.localselectedTags);
    }
  }
}
export default toNative(InputTag);
