import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueTagsInput = _resolveComponent("VueTagsInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_VueTagsInput, {
      ref: "vueAttachedTags",
      modelValue: _ctx.localselectedTags,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localselectedTags) = $event)),
      "existing-tags": _ctx.localexistingTags,
      placeholder: _ctx.tagPlaceholder,
      "typeahead-style": "dropdown",
      "typeahead-activation-threshold": 0,
      limit: 50,
      "add-tags-on-comma": true,
      typeahead: true,
      onTagsUpdated: _ctx.onTagsUpdated
    }, null, 8, ["modelValue", "existing-tags", "placeholder", "onTagsUpdated"])
  ]))
}