import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "gridlisting" }
const _hoisted_2 = { class: "flex-container" }
const _hoisted_3 = { class: "right-container-margin" }
const _hoisted_4 = { class: "right-container-nav" }
const _hoisted_5 = { class: "width25" }
const _hoisted_6 = { class: "width50" }
const _hoisted_7 = { class: "main-heading primary-heading" }
const _hoisted_8 = { class: "add-btn-position" }
const _hoisted_9 = {
  key: 0,
  class: "empty-msg-position"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "margin-top-40" }
const _hoisted_12 = ["indexTag"]
const _hoisted_13 = ["indexTag"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgramTagListViewEditItemPane = _resolveComponent("ProgramTagListViewEditItemPane")!
  const _component_ProgramTagItemEditPane = _resolveComponent("ProgramTagItemEditPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseTag()))
            }, _toDisplayString(_ctx.$t("Button.Close")), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("ProgramTag.ManageTags")), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              id: "btnAddTag",
              class: "btn btn-primary btn-size-default",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.AddTag()))
            }, _toDisplayString(_ctx.$t("Button.Add")), 1)
          ])
        ]),
        ((_ctx.tagItems==null || _ctx.tagItems.length==0)&& _ctx.localTagItem==null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("p", null, [
                _createElementVNode("i", {
                  class: "font-size-14",
                  innerHTML: _ctx.$t('ProgramTag.EmptyScreenMessage')
                }, null, 8, _hoisted_10)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagItems, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.Id,
              indexTag: index,
              class: "margin-top-20"
            }, [
              _createVNode(_component_ProgramTagListViewEditItemPane, {
                "selected-edit-tag-item": item,
                "applicationborder-color": _ctx.loaderBorderColor
              }, null, 8, ["selected-edit-tag-item", "applicationborder-color"])
            ], 8, _hoisted_12))
          }), 128))
        ]),
        (_ctx.createNewTag)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              indexTag: _ctx.tagItems.length
            }, [
              _createVNode(_component_ProgramTagItemEditPane, {
                "selected-tag-item": _ctx.localTagItem,
                "applicationborder-color": _ctx.loaderBorderColor,
                onProgramTagItemCancelOperation: _cache[2] || (_cache[2] = ($event: any) => (_ctx.programTagItemCancelOperation()))
              }, null, 8, ["selected-tag-item", "applicationborder-color"])
            ], 8, _hoisted_13))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}