
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { ProgramSessionQuestionType } from '@/entities/Program/ProgramSessionQuestionType';

@Component
class SessionQuestionsLandingPage extends Vue {
  @Prop() private programQuestionTypes?: ProgramSessionQuestionType[];
  private mounted() {
    // On Mounted Event of Component
  }
  private CreateNewSessionQuestion(type: number) {
    this.$emit('create-new-question-click', type);
  }
  private evenIndex(index: number): boolean {
    if (index % 2 === 0) {
      return true;
    } else {
      return false;
    }
  }
}
export default toNative(SessionQuestionsLandingPage);
