
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import ActionButton from '../ActionButton.vue';
import UploadBulkData from './UploadBulkData.vue';
import ActionButtonType from '@/enums/ActionButtonType';
import ImportDataViewModel from '@/entities/ImportFile/ImportDataViewModel';
import PreviewBulkDataViewModel from '@/entities/ImportFile/PreviewBulkDataViewModel';
import PreviewBulkData from './PreviewBulkData.vue';
@Component({
  components: {
    ActionButton,
    UploadBulkData,
    PreviewBulkData,
  },
})
class ImportFile extends Vue {
  @Prop() private moduleType!: number;
  private importFileData: ImportDataViewModel = ImportDataViewModel.createEmpty();
  private isUploadBulkFileVisible = false;
  private clickedButtonType = ActionButtonType.Create;
  private isPreviewVisible = false;
  private previewBulkData: PreviewBulkDataViewModel = PreviewBulkDataViewModel.createEmpty();
  public get actionButtonType(): typeof ActionButtonType {
    return ActionButtonType;
  }
  private mounted() {
    if(this.moduleType !== null && this.moduleType!== undefined) {
      this.importFileData = ImportDataViewModel.GetImportFileData(this.moduleType);
    }
  }
  // This closes the import pane this is recieved on Pogram.vue.
  private CloseImport() {
    this.$emit('CloseImport');
  }
  private CloseBulkUpload() {
    this.isUploadBulkFileVisible = false;
  }
  // By this UploadBulkData component is shown.
  private ShowUploadBulkData(val: number) {
    this.clickedButtonType = val;
    this.isUploadBulkFileVisible = true;
  }
  // By this PreviewBulkData component is shown along with this it's data is recieved.
  private ShowPreviewPane(showPreview: boolean, previewData: PreviewBulkDataViewModel) {
    this.isPreviewVisible = showPreview;
    this.previewBulkData = previewData;
  }
  // Hides the Import preview pane.
  private HideImportPreview(val: boolean) {
    this.isPreviewVisible = val;
    this.isUploadBulkFileVisible = val;
  }
}
export default toNative(ImportFile);
