import { ProgramImportPreviewData } from './ProgramImportPreviewData';
import SubModuleEnum from '@/enums/SubModuleEnum';
import ImportPropertiesTypes from './ImportPropertiesType';
import { CommonImportFunctions } from './CommonImportFunctions';
import ActionButtonType from '@/enums/ActionButtonType';
import Common from '@/helper/Common';
import { t } from '@/main';
export default class ProgramImport extends ProgramImportPreviewData implements CommonImportFunctions{
  public static createEmpty(): ProgramImport {
    return new ProgramImport();
  }
  constructor(
    public Id: number = 0,
    public PreviewId?: ImportPropertiesTypes,
    public StartDate: Date | null = null,
    public StartTime: Date | null = null,
    public EndDate: Date | null = null,
    public EndTime: Date | null = null,
    public Title: string = '',
    public Track: string = '',
    public RoomLocation: string = '',
    public Groups: string = '',
    public Description: string = '',
    public Tags: string = '',
    public StartDateTime: Date | null = null,
    public StartDateUtc: Date | null = null,
    public EndDateTime: Date | null = null,
    public EndDateUtc: Date | null = null,
    public TimeOffset: number = new Date().getTimezoneOffset(),
  ) {
    super(PreviewId,'','',Title,Description,Track,Tags,RoomLocation,Groups);
  }
  public GetValues(data: any, actionButtonType: number): ProgramImport[] {
    return data.map((item: ProgramImport)=> new ProgramImport((item.Id),{PropName: item.Id!== undefined ? item.Id : 0,ShowInPreview: this.ShowIdInPreview(actionButtonType)},item.StartDate,item.StartTime,item.EndDate,item.EndTime,item.Title,item.Track,item.RoomLocation,Common.TrimSpaceInBetween(item.Groups),item.Description,Common.TrimSpaceInBetween(item.Tags),this.GetFullDate(item.StartDate, item.StartTime),new Date(),this.GetFullDate(item.EndDate, item.EndTime),new Date()));
  }
  public GetTableHeadings(actionButtonType: number) {
    const headings = [t('StartDateAndTime').toString(),t('EndDateAndTime').toString(),t('Title').toString(),t('Description').toString(),t('Program.Track').toString(),t('Program.Tag').toString(),t('Program.Location').toString(),t('Groups').toString()];
    if(actionButtonType === ActionButtonType.Update) {
      headings.unshift(t('ID').toString());
    }
    return headings;
  }
  public GetExcelHeadings(actionButtonType: number) {
    const headings = [t('StartDate').toString(),t('StartTime').toString(),t('EndDate').toString(),t('EndTime').toString(),t('Title').toString(),t('Description').toString(),t('Program.Track').toString(),t('Program.Tag').toString(),t('Program.Location').toString(),t('Groups').toString()];
    if(actionButtonType === ActionButtonType.Update) {
      headings.unshift(t('Id').toString());
    }
    return headings;
  }
  public GetSchema() {
    return {
      'Id': {
        prop: 'Id',
        required: true,
        type: Number,
      },
      'Title': {
        prop: 'Title',
        required: true,
        type: String,
      },
      'Start date': {
        prop: 'StartDate',
        type: Date,
        required: true,
      },
      'End date': {
        prop: 'EndDate',
        type: Date,
        required: true,
      },
      'Start time': {
        prop: 'StartTime',
        type: Date,
        required: true,
      },
      'End time': {
        prop: 'EndTime',
        type: Date,
        required: true,
      },
      'Track': {
        prop: 'Track',
        type: String,
      },
      'Room location': {
        prop: 'RoomLocation',
        type: String,
      },
      'Group(s)': {
        prop: 'Groups',
        type: String,
      },
      'Description': {
        prop: 'Description',
        type: String,
      },
      'Tag(s)': {
        prop: 'Tags',
        type: String,
      },
    };
  }
  public ValidateImportedData(data: any, templateErrors: any, actionButtonType: number) {
    let isTitleMissing = false;
    let isDateTimeMissing = false;
    const titleMaxLength = 120;
    const groupsMaxLength = 100;
    const tagsOrTackMaxLength = 70;
    const maxGroupsOrTagsCount = 20;
    const maxDuration = (8 * 60 + 59) * 60 * 1000; // converting 8:59 minutes into milliseconds
    const minDuration = 1 * 60 * 1000;
    const notADate = 'not_a_date';
    const errors: {index: number[]; message: string[]} = {
      index: [],
      message: [],
    };
    const uniqueIds: any[] = [];
    templateErrors.forEach((element: { reason: string; row: number }) => {
      if(element.reason === notADate) {
        this.InsertErrorsIntoArray(errors.message, t('Error.IncorrectDateFormatInstruction',{instruction: t('Error.ReadImportInstructionsCarefully')}).toString());
        this.InsertErrorsIntoArray(errors.index,(element.row - 2));
      }
    });
    data.forEach((val: ProgramImportPreviewData , index: number) => {
      if(val.Title === '') {
        isTitleMissing = true;
      }
      if((val.FormattedStartDateTime === '' || val.FormattedEndDateTime === '') && !errors.index.includes(index)) {
        isDateTimeMissing = true;
      }
      if(val.PreviewId!== undefined &&  actionButtonType === ActionButtonType.Update) {
        if((val.PreviewId.PropName === undefined || val.PreviewId.PropName === 0)) {
          this.InsertErrorsIntoArray(errors.message, t('Error.InvalidProgramId').toString());
          this.InsertErrorsIntoArray(errors.index,index);
        } else {
          if(uniqueIds.includes(val.PreviewId.PropName)) {
            // if uniqueIds contains the id the index is added to errors
            this.InsertErrorsIntoArray(errors.message,t('Error.ImportContainsDuplicateIds').toString());
            this.InsertErrorsIntoArray(errors.index,index);
          } else {
            // else if the id is not present in the uniqueIds then add it
            uniqueIds.push(val.PreviewId.PropName);
          }
        }
        if(val.PreviewId.PropName % 1 !== 0) {
          this.InsertErrorsIntoArray(errors.message, t('Error.InvalidProgramId').toString());
          this.InsertErrorsIntoArray(errors.index,index);
        }
      }
      if(new Date(val.FormattedStartDateTime).getTime() > new Date(val.FormattedEndDateTime).getTime()) {
        this.InsertErrorsIntoArray(errors.message, t('Error.EndDateTimeGreaterThanStartDateTime').toString());
        this.InsertErrorsIntoArray(errors.index,index);
      }
      const durationError = this.CheckIsDurationIncorrect(String(val.FormattedStartDateTime),String(val.FormattedEndDateTime), maxDuration,minDuration);
      if(durationError!=='') {
        this.InsertErrorsIntoArray(errors.message, durationError);
        this.InsertErrorsIntoArray(errors.index,index);
      }
      if(val.Title === '' || val.FormattedStartDateTime === '' || val.FormattedEndDateTime === '') {
        this.InsertErrorsIntoArray(errors.index,index);
      }
      let recievedValue = '';
      recievedValue = this.GetCharacterLengthOfItem(t('Title').toString(),val.Title,titleMaxLength);
      if(recievedValue !== '') {
        this.InsertErrorsIntoArray(errors.message, recievedValue);
        this.InsertErrorsIntoArray(errors.index,index);
      }
      recievedValue = this.GetCharacterLengthOfItem(t('Program.Track').toString(),val.Track,tagsOrTackMaxLength);
      if(recievedValue !== '') {
        this.InsertErrorsIntoArray(errors.message, recievedValue);
        this.InsertErrorsIntoArray(errors.index,index);
      }
      recievedValue = this.GetCountOfItems(t('Program.Tag').toString(),val.Tags,maxGroupsOrTagsCount,tagsOrTackMaxLength);
      if(recievedValue !== '') {
        this.InsertErrorsIntoArray(errors.message, recievedValue);
        this.InsertErrorsIntoArray(errors.index,index);
      }
      recievedValue = this.GetCountOfItems(t('Groups').toString(),val.Groups,maxGroupsOrTagsCount,groupsMaxLength);
      if(recievedValue !== '') {
        this.InsertErrorsIntoArray(errors.message, recievedValue);
        this.InsertErrorsIntoArray(errors.index,index);
      }
    });
    if(this.MandatoryFieldsError(isTitleMissing,isDateTimeMissing)!=='') {
      this.InsertErrorsIntoArray(errors.message, this.MandatoryFieldsError(isTitleMissing,isDateTimeMissing));
    }
    return errors;
  }
  public GetParentInstance() {
    return new ProgramImportPreviewData();
  }
  private GetCountOfItems(key: string, value: any, maxCount: number, maxItemLength: number) {
    const itemsCreated = value.split(';');
    if(itemsCreated.length > maxCount) {
      return t('Import.CountError',{key,maxCount}).toString();
    } else {
      let error = '';
      itemsCreated.forEach((item: string) => {
        if(this.GetCharacterLengthOfItem(key,item,maxItemLength) !== '' ) {
          error = this.GetCharacterLengthOfItem(key,item,maxItemLength);
          return true;
        }
      });
      return error;
    }
  }
  private GetCharacterLengthOfItem(key: string, item: string, maxLength: number) {
    if(item.length > maxLength) {
      return t('Import.CharacterLengthError',{key,maxLength}).toString();
    } else {
      return '';
    }
  }
  private InsertErrorsIntoArray(errorMessages: any[], error: any) {
    if(!errorMessages.includes(error)) {
      errorMessages.push(error);
    }
  }
  private CheckIsDurationIncorrect(startDateTime: string, endDateTime: string, maxLimit: number, minLimit: number) {
    const duration = new Date(endDateTime).getTime() - new Date(startDateTime).getTime();
    if(duration > maxLimit) {
      return t('Error.MaxDuration').toString();
    } else if(duration < minLimit) {
      return t('Error.MinDuration').toString();
    } else {
      return '';
    }
  }
  private MandatoryFieldsError(IsTitleMissing: boolean, IsDateTimeMissing: boolean ) {
    if(IsTitleMissing && IsDateTimeMissing) {
      return t('Error.MandatoryTitleAndDateTime').toString();
    } else if(IsTitleMissing) {
      return t('Error.MandatoryTitle').toString();
    } else if(IsDateTimeMissing) {
      return t('Error.MandatoryDateTime').toString();
    } else {
      return '';
    }
  }
  private ShowIdInPreview(actionButtonType: number) {
    if(actionButtonType === SubModuleEnum.UpdatePrograms) {
      return true;
    } else {
      return false;
    }
  }
  private GetFullDate(date: Date | null, time: Date | null) {
    if(date!==undefined && date!== null && time!==undefined && time!==null) {
      const dateOffset = date.getTimezoneOffset();
      const timeOffset = time.getTimezoneOffset();
      const convertedDate = new Date(date.getTime() + dateOffset*60000);
      const convertedTime = new Date(time.getTime() + timeOffset*60000);
      return new Date(convertedDate.getFullYear(), convertedDate.getMonth(), convertedDate.getDate(), convertedTime.getHours(), convertedTime.getMinutes());
    }
    return null;
  }
}
