import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db0e7682"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gridlisting inline-flex100" }
const _hoisted_2 = {
  key: 0,
  class: "inline-flex100"
}
const _hoisted_3 = { class: "img-select" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "title-div" }
const _hoisted_6 = { class: "valign" }
const _hoisted_7 = { class: "manage-btn-div" }
const _hoisted_8 = { class: "dropdown inline-flex" }
const _hoisted_9 = { "data-toggle": "dropdown" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "dropdown-menu" }
const _hoisted_12 = {
  key: 1,
  class: "edit-pane-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ProgramMultimediaEditItemPane = _resolveComponent("ProgramMultimediaEditItemPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.localMultimediaItem && !_ctx.showEditMultimedia)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "iconImg",
              src: _ctx.GetImageUrl(_ctx.localMultimediaItem)
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.localMultimediaItem.Title), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("img", {
                  src: _ctx.manageIcon,
                  class: "manage-icon"
                }, null, 8, _hoisted_10)
              ]),
              _createElementVNode("ul", _hoisted_11, [
                _createElementVNode("li", {
                  class: "edit-padding",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectItem(_ctx.localMultimediaItem,false)))
                }, [
                  _createElementVNode("a", null, _toDisplayString(_ctx.$t("Button.Edit")), 1)
                ]),
                _createElementVNode("li", {
                  class: "edit-padding",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.DeleteSelectedMultimedia(_ctx.localMultimediaItem)))
                }, [
                  _createElementVNode("a", null, _toDisplayString(_ctx.$t("Button.Delete")), 1)
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showEditMultimedia)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createVNode(_component_ProgramMultimediaEditItemPane, {
            "selected-multimedia-item": _ctx.localMultimediaItem,
            "applicationborder-color": _ctx.loaderBorderColor,
            onProgramMultimediaItemEditImageOperation: _cache[2] || (_cache[2] = ($event: any) => (_ctx.programMultimediaItemImageEditedOperation($event))),
            onProgramMultimediaItemCancelOperation: _cache[3] || (_cache[3] = ($event: any) => (_ctx.programMultimediaItemCancelOperation($event))),
            onProgramMultimediaItemEditOperation: _cache[4] || (_cache[4] = ($event: any) => (_ctx.programMultimediaItemEditedOperation($event))),
            onProgramMultimediaItemImageUploadOperation: _cache[5] || (_cache[5] = ($event: any) => (_ctx.programMultimediaItemImageUploadOperation($event)))
          }, null, 8, ["selected-multimedia-item", "applicationborder-color"])
        ]))
      : _createCommentVNode("", true)
  ]))
}