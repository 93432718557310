
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import {
  ProgramItem,
} from '@/entities/Program/ProgramItem';
import MapPicker from '@/components/Common/MapPicker.vue';
import { ProgramTrackItem } from '@/entities/Program/ProgramTrackItem';
import { ProgramTagItem } from '@/entities/Program/ProgramTagItem';
import { ProgramDurationItem } from '@/entities/Program/ProgramDurationItem';
import ImageProcess from '@/components/Common/ImageProcess.vue';
import ProgramAddInformation from '@/components/Program/AddInformation/ProgramAddInformation.vue';
import AddInformationWithEntity from '@/components/AddInformation/AddInformationWithEntity.vue';
import InpuTag from '@/components/Common/InputTag.vue';
import Datepicker from '@/components/Common/Datepicker.vue';
import Editor from '@/components/Common/Editor.vue';
import ProgramSessionQuestionsListView from '@/components/Program/ProgramDetail/SessionQuestions/ProgramSessionQuestionsListView.vue';
import ProgramEvaluationQuestionsListView from '@/components/Program/ProgramDetail/EvaluationQuestions/ProgramEvaluationQuestionsListView.vue';
import ProgramLinkedInformationListView from '@/components/Program/ProgramDetail/LinkedInformation/ProgramLinkedInformationListView.vue';
import MultimediaListView from '@/components/Program/ProgramDetail/Multimedia/MultimediaListView.vue';
import loading from '@/components/Common/loading.vue';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import FileUpload from '@/components/Common/FileUpload.vue';
import { MultimediaItem } from '@/entities/Program/MultimediaItem';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import VirtualMeetingDetails from '@/components/Common/VirtualMeetings/VirtualMeetingDetails.vue';
import PreRecordedMeetingHighcharts from '@/components/Common/PreRecordedMeetingHighcharts.vue';
import VirtualMeetingSessionAnalytics from '@/components/Common/VirtualMeetings/VirtualMeetingSessionAnalytics.vue';
import VirtualMeetingCombineAnalytics from '@/components/Common/VirtualMeetings/VirtualMeetingCombineAnalytics.vue';
import { VonageSessionData } from '@/entities/VonageSessionData';

import {
  ProgramSessionQuestionsItem,
} from '@/entities/Program/ProgramSessionQuestionsItem';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { ProgramSignalRModel } from '@/signalRRequest/Program/ProgramSignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import Common from '@/helper/Common';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { FontAwesomeIcon } from '@/entities/FontAwesomeViewModel';
import { ProgramEvaluationQuestionsItem } from '@/entities/Program/ProgramEvaluationQuestionsItem';
import { VodDetail, VodVideoMetricsRequest } from '@/entities/VodDetail';
import ListOfParticipantGroups from '@/components/Common/ListOfParticipantGroups.vue';
import { ParticipantGroups } from '@/entities/ParticipantGroups';
import { VodBaseRenderModel } from '@/entities/FactoryPattern/Vod/VodBaseRenderModel';
import { VodEntityItemModel } from '@/entities/VodEntityItemModel';
import { VodService } from '@/entities/FactoryPattern/Vod/VodService';
import  MuxVideo  from '@/components/Common/MuxVideo.vue';
import VodTypeEnum from '@/enums/VodTypeEnums';
import VirtualMeetingtypeParentEnum from '@/entities/VirtualMeetingType';
import ModuleEnum from '@/enums/ModuleEnum';
import EventWebPageVisibiltity from '@/components/Common/EventWebPageVisibiltity.vue';
import { FetchData, Root, Store, t } from '@/main';
import useVuelidate from '@vuelidate/core';
import { reactive } from 'vue';
import { maxValue, minLength, minValue, required } from '@vuelidate/validators';
import SessionCheckIn from '@/components/Common/SessionCheckIn.vue';
import { SessionCheckInViewModel } from '@/entities/SessionCheckInViewModel';
import LiveRecordedVideoAnalytics from '@/entities/Emits/WatchLive/LiveRecordedVideoAnalytics';
import SubModulePrefix from '@/enums/SubModulePrefix';
import CheckedInParticipantDetails from '@/components/Common/CheckedInParticipantDetails.vue';
import { CheckedInParticipantDetailViewModel } from '@/entities/CheckedInParticipantDetailViewModel';
import SessionChat from '@/components/Common/SessionChat.vue';

@Component({
  components: {
    MapPicker,
    ImageProcess,
    InpuTag,
    Datepicker,
    Editor,
    ProgramSessionQuestionsListView,
    ProgramLinkedInformationListView,
    MultimediaListView,
    ProgramEvaluationQuestionsListView,
    loading,
    ProgramAddInformation,
    FileUpload,
    ModalPopup,
    ValidationSummary,
    VirtualMeetingDetails,
    PreRecordedMeetingHighcharts,
    VirtualMeetingSessionAnalytics,
    VirtualMeetingCombineAnalytics,
    ListOfParticipantGroups,
    MuxVideo,
    AddInformationWithEntity,
    EventWebPageVisibiltity,
    SessionCheckIn,
    CheckedInParticipantDetails,
    SessionChat
  },
})
class ProgramEditItemPane extends Vue {
  @Prop() private selectedProgramItem?: ProgramItem;
  @Prop() private availableTracks?: ProgramTrackItem[];
  @Prop() private availableTags?: ProgramTagItem[];
  @Prop() private availableDurations?: ProgramDurationItem[];
  @Prop() private applicationborderColor?: string;
  @Prop() private availableCustomCategoryFolders?: CustomCategoryFolder[];
  @Prop() private applicationId?: number;
  @Prop() private applicationInstanceId?: number;
  @Prop() private newProgramStartDate?: Date;
  @Prop() private isEnableProgramQuestion?: boolean;
  @Prop() private previouslySelectedFontAwesomeIcon?: FontAwesomeIcon;
  @Prop() private globalProgramEvaluation?: boolean;
  @Prop() private participantGroups?: ParticipantGroups[];
  private localCustomCategoryFolders: CustomCategoryFolder[] = [];
  private localProgramItem: ProgramItem | null = null;
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private featureMissingImage: string = require('@/assets/Images/ImageMissingFeature.png');
  private missingVideo: string = require('@/assets/Images/Play_75x75.png');
  private showImageProcess = false;
  private isimageIconClicked = false;
  private alreadyselectedDate: Date = new Date();
  private showAddInformationProcess = false;
  private showAddVideoProcess = false;
  private showPreRecordedAnalytics = false;
  private showVirtualMeetingSessionAnalytics = false;
  private showMeetAppLiveRecordingSessionAnalytics = false;
  private customToolbar: any = [['bold', 'italic', 'underline', 'link']];
  private selectedTags: any = [];
  private existingTags: any = [];
  private allDurations: any = [];
  private durationHours: number[] = [];
  private durationMinutes: number[] = [];
  private defaultTrack: ProgramTrackItem | null = null;
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private appInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
  private modalPopupContent = '';
  private fileUploadGetAssetPreUploadUrl = '/Program/GetAssetObjectPreUpload';
  private showSection_ModalPopup = '';
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  public showLoading = false;
  public loaderBorderColor = '';
  private localAllInformations?: CustomCategoryItem[] = [];
  private showFileUploadProcess = false;
  private showMultimediaFileUploadProcess = false;
  private currentEditMultimediaItem: MultimediaItem | null = null;
  private currentEdit360ImageItem: MultimediaItem | null = null;
  private imageType360Item = '360image';
  private video = 'Video';
  private modalPopupVisible = false;
  private showCloseButton = true;
  // private modalPopupContent: string = this.getMoveToAnotherProgramMessage();
  private programItemTobeSaved: ProgramItem = ProgramItem.createEmpty();
  private messages: string[] = [];
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  private listOfParticipantGroups: ParticipantGroups[] = [];
  // constants
  private readonly defaultImageWidth = 320;
  private readonly defaultImageHeight = 200;
  private readonly ProgramModule = 'Program';
  private isMobileView = false;
  private percantageVideoUploaded = 0;
  private SelectedVirtualMeetingOption = 0;
  private readonly NotificationSessionOrEvaluationQuestionUpdated =
    'NotificationSessionOrEvaluationQuestionUpdated';
  private readonly NotificationProgramDeleted = 'NotificationProgramDeleted';
  private isTimezoneInfoVisible = false;
  private isRecordedSessionLocal = false;
  private isRecordedAutomaticallyLocal = false;
  private vonageAnalyticslocal: VonageSessionData = VonageSessionData.createEmpty();
  private selectedMeetingOption = 0;
  private hasSelectedGroups = false;
  private isEnableLinkProgramItemsToGroup = false;
  private isEnableFeaturedImage = false;
  private subHeadingString = t('Program.ListOfParticipantGroups.ApplyToSelectedParticipantGroupsProgramSubHeading');
  private selectedVodRenderer: VodBaseRenderModel = VodBaseRenderModel.createEmpty();
  private selectedItem?: VodEntityItemModel = VodEntityItemModel.createEmpty();
  private entityType = ModuleEnum.Program;
  private localIsEnableProgramQuestion = false;
  private subModuleName = SubModulePrefix.IconImage;
  private state = reactive({
    localProgramItem: null as ProgramItem | null,
  });
  private sessionCheckInData: SessionCheckInViewModel = SessionCheckInViewModel.createEmpty();
  private showCheckedInParticipantDetails = false;
  private minParticipantCheckInLimit = 1;
  private maxParticipantCheckInLimit = 100000;
  private checkedInParticipantDetails: CheckedInParticipantDetailViewModel[] = [];
  private deleteIcon = require('@/assets/Images/Delete-icon.svg');

  private rules = {
    localProgramItem: {
      Title: {
        required,
        minLength: minLength(1),
      },
      MaxCheckInLimitQuantity: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(100000),
      },
    },
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);
  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  private async Submit(val: any) {
    val.$touch();
    const invalidDuration =
      this.localProgramItem!.Duration!.Hours === 0 &&
      this.localProgramItem!.Duration!.Minutes === 0;
    const res = !await val.$validate();
    if (res || invalidDuration) {
      this.validationErrorFields = [];
      if (val.localProgramItem.Title!.$error === true) {
        this.validationErrorFields.push(
          this.$t('Title').toString(),
        );
      }
      if (invalidDuration) {
        this.validationErrorFields.push(
          this.$t('Program.Duration.ErrorMessage').toString(),
        );
      }
      if(val.localProgramItem.MaxCheckInLimitQuantity!.$error === true && (this.localProgramItem!.MaxCheckInLimitQuantity !== -1 && this.localProgramItem!.IsSessionCheckInEnabled)) {
        this.validationErrorFields.push(
          this.$t('SessionCheckIn.MinimumMaximumCheckInLimit',{min: this.minParticipantCheckInLimit, max: this.maxParticipantCheckInLimit}).toString(),
        );
      }
      if(this.validationErrorFields.length !== 0) {
        this.visibleValidationSummary = true;
        return;
      }
    }
    if (this.hasSelectedGroups === true && this.localProgramItem!.ParticipantGroups.length <= 0) {
      this.validationErrorFields = [];
      this.validationErrorFields.push(
        this.$t('Program.NoParticipantGroupSelected').toString(),
      );
      this.visibleValidationSummary = true;
      return;
    }
    if (this.isVirtualMeetingDetailsFeatureActive()) {
      this.validationErrorFields = [];

      if ( this.localProgramItem!.VirtualMeetingLink !== null && this.localProgramItem!.VirtualMeetingLink.trim() !== '' && this.localProgramItem!.VirtualMeetingType === '') {
        this.validationErrorFields.push(
          this.$t('VirtualMeetings.Validation.LinkValidation').toString(),
        );
        this.visibleValidationSummary = true;
        return;
      }

      if ( this.localProgramItem!.VirtualMeetingLink !== null && this.localProgramItem!.VirtualMeetingLink.trim() !== '' && this.localProgramItem!.VirtualMeetingType !== '' && this.localProgramItem!.VirtualMeetingType !== 'Vonage') {

        // const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[aA-zZ\u00a1-\uffff0-9]-*)*[aA-zZ\u00a1-\uffff0-9]+)(?:\.(?:[aA-zZ\u00a1-\uffff0-9]-*)*[aA-zZ\u00a1-\uffff0-9]+)*(?:\.(?:[aA-zZ\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        const regExpYoutube = /^(^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?.+)?$/;
        const regExpVimeo = /^(^(http(s)?:\/\/)?((w){3}.)?vimeo?(\.com)?.+)?$/;

        if (!regexp.test(this.localProgramItem!.VirtualMeetingLink.trim().toLowerCase())) {
          this.validationErrorFields.push(
            this.$t('VirtualMeetings.Validation.LinkValidation').toString(),
          );
          this.visibleValidationSummary = true;
          return;
        }
        const virtualMeetingLink = this.localProgramItem!.VirtualMeetingLink.toLowerCase().trim();
        if (this.SelectedVirtualMeetingOption === 1) {
          if (!regExpYoutube.test(virtualMeetingLink) && !regExpVimeo.test(virtualMeetingLink)) {
            this.validationErrorFields.push(
              this.$t('VirtualMeetings.Validation.InValidVimeoYoutube').toString(),
            );
            this.visibleValidationSummary = true;
            return;
          }
        }
      }
    }
    val.$reset();
    this.visibleValidationSummary = false;
    // No errors submitting.

    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor!;
    }
    this.FillProgramItemTobeSaved();
    const signalObject = this.SendNotificationRequest(false);

    const objForJson = {
      Latitude: this.programItemTobeSaved.Latitude,
      Longitude: this.programItemTobeSaved.Longitude,
    };
    FetchData('/Program/SaveOrUpdateProgram', JSON.stringify({
      pm: this.programItemTobeSaved,
      jsonData : JSON.stringify(objForJson),
    }))
      .then((response) => {
        if (response.ValidationErrors.length > 0) {
          // Server side validation failuere
          alert(this.$t(response.ValidationErrors[0]));
        } else {
          // Successful update / save
          response.Program.ImageUrl = this.localProgramItem!.ImageUrl;
          response.Program.MainMediaFullUrl = this.localProgramItem!.MainMediaFullUrl;
          response.Program.Duration = this.localProgramItem!.Duration;
          // clear controls after submit the page.
          if (this.localProgramItem!.Id === 0) {
            Root.emit('clearDescription', '');
            Root.emit('clearTags');
          }
          this.UpdateProgramTags(signalObject, response.Program as ProgramItem);
        }
        setTimeout(() => {
          this.ClearMessagesList();
        }, 5000);
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  private UpdateProgramTags(
    signalObject: SignalRModelRequest,
    program: ProgramItem,
  ) {
    FetchData('/Program/GetProgramTagsByProgramId', JSON.stringify({
      programId: program.Id,
    }))
      .then((response) => {
        program.ProgramTags = response;
        this.SendNotificationRequestToQueue(signalObject, program);
      })
      .catch(() => {
        // Log error when required
      });
  }
  private CheckTimeFormat(): boolean {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    return browsersettings.isFullDayTimeFormat;
  }
  private SendNotificationRequest(
    isDelete: boolean,
    isProgramDetails?: boolean,
  ): SignalRModelRequest {
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    Root.emit('program-uncheck-select-all');
    Root.emit('empty-track-tag');
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(
      Root,
      Store,
      this.$t,
    );
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    if (isDelete) {
      signalObject = signalR.SingleDeleteProgramItemSignalRModelRequest;
      const arr: number[] = [];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      arr.push(this.localProgramItem!.Id);
      signalObject.SelectedIds = arr;
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        selectedCount: arr.length, // Just single item delete
        user: signalR.GetUserName(
          signalObject,
          this.$t('UserName.You').toString(),
        ),
      }).toString();
    } else {
      if (this.localProgramItem !== null && this.localProgramItem !== undefined && this.localProgramItem.Id === 0) {
        signalObject = signalR.SingleCreateProgramItemSignalRModelRequest;
      } else {

        signalObject =
          isProgramDetails === true
            ? signalR.SingleDetailEditProgramItemSignalRModelRequest
            : signalR.SingleUpdateProgramItemSignalRModelRequest;
        const arr: number[] = [];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        arr.push(this.localProgramItem!.Id);
        signalObject.SelectedIds = arr;
      }
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        user: signalR.GetUserName(
          signalObject,
          this.$t('UserName.You').toString(),
        ),
      }).toString();
    }
    signalObject.ApplicationId = Number(this.appInfo.ApplicationId);
    signalObject.EventId = Number(this.appInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({
      selectedName: this.localProgramItem!.Title,
    });
    signalR.DisableProgramItemsAndAddToNotificationPopup(signalObject);
    return signalObject;
  }
  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    programItem: ProgramItem,
  ) {
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(
      Root,
      Store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(programItem.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = signalR.GetProgramItemToSendToSignalR(programItem);
    signalR.PerformProgramSingleOperation(signalObject);
  }
  private SendNotificationRequestToQueueForProgramDetails(
    programDetailItem: any,
  ) {
    const signalObject = this.SendNotificationRequest(false, true);
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(
      Root,
      Store,
      this.$t,
    );
    programDetailItem.Title = this.selectedProgramItem!.Title;
    signalObject.JSONData = signalR.GetProgramDetailItemToSendToSignalR(
      programDetailItem,
    );
    signalR.PerformProgramSingleOperation(signalObject, true);
  }
  private mounted() {
    this.isEnableLinkProgramItemsToGroup = this.appInfo.EnableLinkProgramItemsToGroup;
    this.isEnableFeaturedImage = !this.isEnableLinkProgramItemsToGroup;
    this.UpdateProgramDetails();
    this.SetVodDetail();
    this.ClearMessagesList();
    this.localCustomCategoryFolders = this.availableCustomCategoryFolders || [];
    this.updateTags();
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.localIsEnableProgramQuestion = this.isEnableProgramQuestion!;
    Root.on('sendListOfParticipantGroupsInLocalItem', (selectedParticipantGroups: ParticipantGroups[]) => {
      this.localProgramItem!.ParticipantGroups = selectedParticipantGroups;
    });

    Root.on(
      'set-selected-meeting-type',
      (type: string) => {
        this.selectedProgramItem!.VirtualMeetingType = type;
      },
    );
    // values of meetApp live recording
    Root.on(
      'set-IsRecorded-Session',
      (val: boolean) => {
        if (this !== undefined) {
          this.isRecordedSessionLocal = val;
        }
      },
    );
    Root.on(
      'set-IsRecorded-Automatically',
      (val: boolean) => {
        this.isRecordedAutomaticallyLocal = val;
      },
    );
    Root.on(
      'program-multimedia-item-delete-operation',
      (multimediaId: number) => {
        this.programMultimediaItemDeletedOperation(multimediaId);
      },
    );
    Root.on(
      'program-multimedia-item-edit-operation',
      (multimedia: MultimediaItem) => {
        this.programMultimediaItemEditedOperation(multimedia);
      },
    );

    Root.on(
      'program-360Image-item-delete-operation',
      (multimediaId: number) => {
        this.program360ImageItemDeletedOperation(multimediaId);
      },
    );
    Root.on(
      'program-360image-item-edit-operation',
      (multimedia: MultimediaItem) => {
        this.program360ImageItemEditedOperation(multimedia);
      },
    );
    // make image process component false if other program item selected
    Root.on('show-image-process', (imageProcess: boolean) => {
      this.showImageProcess = imageProcess;
      this.showMultimediaFileUploadProcess = false;
    });
    Root.on('show-add-video-section-byEntity', (addVideo: boolean) => {
      Root.on('show-add-video-section-vod-byEntity', (addVideoCallFrom: number) => {
        this.selectedMeetingOption = addVideoCallFrom;
        if (addVideoCallFrom === VirtualMeetingtypeParentEnum.PreRecordedVideo) {
          this.localProgramItem!.VirtualMeetingType = VodTypeEnum[VodTypeEnum.Mux].toString();
        }
      });
      this.showAddVideoProcess = addVideo;
    });
    Root.on('show-graph-comprehensive-data',
      (showVirtualMeetingSession: boolean, objVonageSessionData: VonageSessionData) => {
        this.showVirtualMeetingSessionAnalytics = showVirtualMeetingSession;
        this.vonageAnalyticslocal = objVonageSessionData;
      });
    Root.on('show-pre-recorded-video-analytics-section', (showChart: boolean) => {
      this.showPreRecordedAnalytics = showChart;
    });

    Root.on('show-meetapp-live-recorded-video-analytics-section', (data: LiveRecordedVideoAnalytics) => {
      this.showMeetAppLiveRecordingSessionAnalytics = data.ShowChart;
      this.vonageAnalyticslocal = data.VonageAnalytics;
    });
    Root.on(
      'program-sessionQuestion-item-removed-operation',
      (programSessionQuestionItemId: number) => {
        this.programSessionQuestionItemDeletedOperation(
          programSessionQuestionItemId,
        );
      },
    );
    Root.on(
      'program-evaluationQuestion-item-removed-operation',
      (programEvaluationQuestionItemId: number) => {
        this.programEvaluationQuestionItemDeletedOperation(
          programEvaluationQuestionItemId,
        );
      },
    );
    Root.on('multimedia-update-signalr', (multimediaItemData: any) => {
      this.SendNotificationRequestToQueueForProgramDetails(multimediaItemData);
    });
    Root.on('vod-save-current-progress', (percentageuploaded: number) => {
      this.percantageVideoUploaded = percentageuploaded;
      this.selectedVodRenderer.PercentageVideoUploaded = percentageuploaded;
    });
    Root.on('program-scroll-to-element', (elementId: string) => {
      this.scrollToElement(elementId);
    });
    Root.on(
      'new-program-edit-item-pane',
      (isNewEditItemPane: boolean) => {
        if (isNewEditItemPane) {
          // scroll to top if any program edit or new program clicked
          if (!this.isMobileView) {
            Common.ScrollToTop('programEditMode');
          }
        }
      },
    );

    Root.on(
      'update-attached-program-items-with-session-questions',
      (notification: SignalRModelRequest) => {
        if (this.localProgramItem) {
          notification.SelectedIds.forEach((element) => {
            const obj = this.localProgramItem!.SessionQuestions!.find(
              (o) => o.id === element,
            );
            if (obj) {
              this.ShowUpdatedProgramDetailPopup(notification);
            } else {
              const pq: any = JSON.parse(notification.JSONData);
              let objIndex = -1;
              if (pq !== null) {
                if (pq.attachedProgramIds) {
                  objIndex = pq.attachedProgramIds.findIndex(
                    (id: number) => id === this.localProgramItem!.Id,
                  );
                } else if (pq.selectedProgramIds) {
                  objIndex = pq.selectedProgramIds.findIndex(
                    (id: number) => id === this.localProgramItem!.Id,
                  );
                }
              }
              if (objIndex > -1) {
                this.ShowUpdatedProgramDetailPopup(notification);
              }
            }
          });
        }
      },
    );
    Root.on(
      'selected-meeting-type-selected-option',
      (optionId: number) => {
        this.SelectedVirtualMeetingOption = optionId;
      },
    );
    Root.on(
      'update-attached-program-items-with-evaluation-questions',
      (notification: SignalRModelRequest) => {
        if (this.localProgramItem) {
          notification.SelectedIds.forEach((element) => {
            const obj = this.localProgramItem!.EvaluationQuestions!.find(
              (o) => o.Id === element,
            );
            if (obj) {
              this.ShowUpdatedProgramDetailPopup(notification);
            } else {
              const pq: any = JSON.parse(notification.JSONData);
              let objIndex = -1;
              if (pq !== null) {
                if (pq.SelectedProgramIds) {
                  const strVale = pq.SelectedProgramIds;
                  const resultArray = strVale.split(',').map(
                    function(
                      strVal: string,
                    ) {
                      return Number(strVal);
                    },
                  );
                  objIndex = resultArray.findIndex(
                    (id: number) => id === this.localProgramItem!.Id,
                  );
                } else if (pq.SelectedPrograms === 'All') {
                  this.ShowUpdatedProgramDetailPopup(notification);
                }
              }
              if (objIndex > -1) {
                this.ShowUpdatedProgramDetailPopup(notification);
              }
            }
          });
        }
      },
    );
    this.UpdateInformationUsingSignalR();
    Root.on('reload-VodDetail-delete-program', () => {
      this.selectedVodRenderer.VodDetail = VodDetail.createEmpty();
      this.selectedProgramItem!.VodDetail = VodDetail.createEmpty();
    });
    Root.on('set-vod-base-renderer', (val: VodBaseRenderModel)=> {
      this.selectedVodRenderer.SubModuleType = val.SubModuleType;
      this.selectedVodRenderer.SubModuleTypeValue = val.SubModuleTypeValue;
    });
  }
  private SetVodRenderer(val: VodBaseRenderModel) {
    this.selectedVodRenderer.SubModuleType = val.SubModuleType;
    this.selectedVodRenderer.SubModuleTypeValue = val.SubModuleTypeValue;
  }
  private IsEventWebpagePFEnabled() {
    return this.appInfo.IsEventWebPagePFActive && this.appInfo.EnableEventWebPage;
  }
  private SetEventWebpageCheckboxValue(val: boolean) {
    this.localProgramItem!.HideFromEventWebpage = val;
  }

  // set session chat checkbox value
  private SetSessionChatCheckboxValue(val: boolean) {
    this.localProgramItem!.IsSessionChatEnabled = val;
  }
  
  private SetGroupVisibilityValue(val: boolean) {
    this.localProgramItem!.DisplayGroupInfoInApp = val;
  }
  private GetCheckedInParticipantData(val: boolean) {
    this.showCheckedInParticipantDetails = val;
    if(this.showCheckedInParticipantDetails) {
      FetchData('/Program/GetCheckedInParticipantsDetails', JSON.stringify({
        programId: this.localProgramItem!.Id,
      }))
        .then((response) => {
          this.checkedInParticipantDetails = response;
        })
        .catch(() => {
          alert('Error');
        });
    }
  }
  private SaveOrUpdateSessionCheckInData(val: SessionCheckInViewModel) {
    if(this.localProgramItem !== null && this.localProgramItem !== undefined) {
      this.localProgramItem.IsSessionCheckInEnabled = val.IsSessionCheckInEnabled;
      this.localProgramItem.MaxCheckInLimitQuantity = val.MaxCheckInLimitQuantity;
    }
  }
  private GetSessionCheckInData() {
    if(this.localProgramItem !== null && this.localProgramItem !== undefined) {
      this.sessionCheckInData.Id = this.localProgramItem.Id;
      this.sessionCheckInData.IsSessionCheckInEnabled =  this.localProgramItem.IsSessionCheckInEnabled;
      this.sessionCheckInData.CheckedInParticipantsCount = this.localProgramItem.CheckedInParticipantsCount;
      this.sessionCheckInData.MaxCheckInLimitQuantity = this.localProgramItem.MaxCheckInLimitQuantity;
      this.sessionCheckInData.MinParticipantCheckInLimit = this.minParticipantCheckInLimit;
      this.sessionCheckInData.MaxParticipantCheckInLimit = this.maxParticipantCheckInLimit;
    }
  }
  private ShowUpdatedProgramDetailPopup(notification: SignalRModelRequest) {
    this.modalPopupVisible = true;
    this.showCloseButton = false;
    this.showSection_ModalPopup = this.NotificationSessionOrEvaluationQuestionUpdated;
    this.modalPopupShowCancelButton = false;
    this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
    this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
    this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
      username: notification.UserName,
    }).toString();
  }
  // update custom category information using signalr.
  private UpdateInformationUsingSignalR() {
    Root.on('program-customcategory-item-added', (obj: any) => {
      const val = {
        showAddInformation: obj.showAddInformation,
        informations: obj.programQuestionDetails.dataItem,
      };
      this.showAttachInformation(val);
    });
  }
  private UpdateProgramDetails() {
    Root.on(
      'update-program-details',
      (selectedProgramItem: ProgramItem) => {
        this.localProgramItem!.SessionQuestions =
          selectedProgramItem.SessionQuestions;
        this.localProgramItem!.EvaluationQuestions =
          selectedProgramItem.EvaluationQuestions;
        this.localProgramItem!.ProgramMedias360 =
          selectedProgramItem.ProgramMedias360;
        this.localProgramItem!.LinkedInformation =
          selectedProgramItem.LinkedInformation;
        this.localProgramItem!.ProgramMedias =
          selectedProgramItem.ProgramMedias;

        this.localProgramItem!.ProgramQuestionLinkExpirationDate =
          selectedProgramItem.ProgramQuestionLinkExpirationDate;
        this.localProgramItem!.ProgramQuestionPublishedLink =
          selectedProgramItem.ProgramQuestionPublishedLink;
        this.localProgramItem!.VodDetail =
          selectedProgramItem.VodDetail;
        this.localProgramItem!.HasExternalZoomLink = selectedProgramItem.HasExternalZoomLink;
        this.localProgramItem!.CheckedInParticipantsCount = this.sessionCheckInData.CheckedInParticipantsCount = selectedProgramItem.CheckedInParticipantsCount;
        this.isRecordedSessionLocal = selectedProgramItem.IsRecordedSession;
        this.isRecordedAutomaticallyLocal = selectedProgramItem.IsRecordedAutomatically;
      },
    );
  }

  // Created new variable to save program item and lighten the object.
  // removed additional array items which don't need while saving program item
  private FillProgramItemTobeSaved() {
    Object.assign(this.programItemTobeSaved, this.localProgramItem);
    this.programItemTobeSaved.IsRecordedSession = this.isRecordedSessionLocal;
    this.programItemTobeSaved.IsRecordedAutomatically = this.isRecordedAutomaticallyLocal;
    this.programItemTobeSaved.Timeoffset  = new Date().getTimezoneOffset();
    this.programItemTobeSaved.ProgramMedias = [];
    this.programItemTobeSaved.ProgramMedias360 = [];
    this.programItemTobeSaved.EvaluationQuestions = [];
    this.programItemTobeSaved.SessionQuestions = [];
    this.programItemTobeSaved.LinkedInformation = [];
    this.programItemTobeSaved.CustomCategoryFolders = [];
    if(this.localProgramItem!.ParticipantGroups.length === 0) {
      this.programItemTobeSaved.DisplayGroupInfoInApp = false;
    }
  }

  private programMultimediaItemDeletedOperation(multimediaId: number) {
    const objIndex = this.localProgramItem!.ProgramMedias!.findIndex(
      (obj: MultimediaItem) => obj.Id === multimediaId,
    );
    if (objIndex > -1) {
      this.localProgramItem!.ProgramMedias!.splice(objIndex, 1);
    }
  }

  private programMultimediaItemEditedOperation(multimedia: MultimediaItem) {
    const objIndex = this.localProgramItem!.ProgramMedias!.findIndex(
      (obj: MultimediaItem) => obj.Id === multimedia.Id,
    );
    if (objIndex === -1) {
      // Created
      this.localProgramItem!.ProgramMedias!.push(multimedia);
    } else {
      // Updated
      if(this.localProgramItem !== null && this.localProgramItem !== undefined && this.localProgramItem.ProgramMedias !== null && this.localProgramItem.ProgramMedias !== undefined) {
        this.localProgramItem.ProgramMedias[objIndex] = multimedia;
      }
    }
  }

  private program360ImageItemDeletedOperation(multimediaId: number) {
    const objIndex = this.localProgramItem!.ProgramMedias360!.findIndex(
      (obj: MultimediaItem) => obj.Id === multimediaId,
    );
    if (objIndex > -1) {
      this.localProgramItem!.ProgramMedias360!.splice(objIndex, 1);
    }
  }

  private program360ImageItemEditedOperation(multimedia: MultimediaItem) {
    const objIndex = this.localProgramItem!.ProgramMedias360!.findIndex(
      (obj: MultimediaItem) => obj.Id === multimedia.Id,
    );
    if (objIndex === -1) {
      // Created
      this.localProgramItem!.ProgramMedias360!.push(multimedia);
    } else {
      // Updated
      if(this.localProgramItem !== null && this.localProgramItem !== undefined && this.localProgramItem.ProgramMedias360 !== null && this.localProgramItem.ProgramMedias360 !== undefined) {
        this.localProgramItem.ProgramMedias360[objIndex] = multimedia;
      }
    }
  }

  // Update Session Questions List for Program Item when a session question linking is removed from it.
  private programSessionQuestionItemDeletedOperation(
    sessionQuestionId: number,
  ) {
    const objIndex = this.localProgramItem!.SessionQuestions!.findIndex(
      (obj: ProgramSessionQuestionsItem) => obj.id === sessionQuestionId,
    );
    if (objIndex > -1) {
      this.localProgramItem!.SessionQuestions!.splice(objIndex, 1);
    }
  }
  // Update Evaluation Questions List for Program Item when a evaluation question linking is removed from it.
  private programEvaluationQuestionItemDeletedOperation(
    questionId: number,
  ) {
    const objIndex = this.localProgramItem!.EvaluationQuestions!.findIndex(
      (obj: ProgramEvaluationQuestionsItem) => obj.Id === questionId,
    );
    if (objIndex > -1) {
      this.localProgramItem!.EvaluationQuestions!.splice(objIndex, 1);
    }
  }

  private Cancel(val: any) {
    val.$reset();
    const item = this.selectedProgramItem;
    this.$emit('program-item-cancel-operation', item);
    // clear all checkboxes in program list
    Root.emit('clearCheckboxes', []);
  }
  private selectedImageUrl(imgUrl: string) {
    this.localProgramItem!.ImageUrl = imgUrl;
  }
  private created() {
    this.localProgramItem = Object.assign({}, this.selectedProgramItem);
    this.state.localProgramItem = this.localProgramItem;
    this.GetSessionCheckInData();
    this.durationHours = Common.prototype.GetHours();
    this.durationMinutes = Common.prototype.GetMinutes();
    this.allDurations = this.availableDurations;
    if (this.localProgramItem.Duration == null) {
      this.localProgramItem.Duration = this.allDurations.find(
        (obj: ProgramDurationItem) => obj.Minutes === 60,
      );
    }
    const newItem = ProgramTrackItem.createEmpty();
    this.defaultTrack = newItem;
    if (this.localProgramItem.Track == null) {
      this.localProgramItem.Track = newItem;
    }
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;

    if (confirmed) {
      if (
        this.showSection_ModalPopup ===
        this.NotificationSessionOrEvaluationQuestionUpdated
      ) {
        Root.emit('reload-program-detail');
      } else if (
        this.showSection_ModalPopup === this.NotificationProgramDeleted
      ) {
        this.DeleteProgram();
      }
    } else {
      return;
    }
  }
  private getMoveToAnotherProgramMessage(): string {
    return this.$t('MoveToAnotherScreen.Message').toString();
  }

  private isTimeZoneFeatureActive(): boolean {
    const timeZone: string = this.storeHelper.GetActiveEventIanaTimeZoneFromStore();

    return (this.appInfo.AllowEnableTimeZoneFeature && timeZone != null);
  }

  private isVirtualMeetingDetailsFeatureActive(): boolean {
    return this.appInfo.EnableVirtualMeetingIntegration;
  }

  private getTimeZoneTitle(): string {
    const timeZone: string = this.storeHelper.GetActiveEventIanaTimeZoneFromStore();
    return this.$t('Program.Timezone', {
      timezoneName: timeZone,
    }).toString();
  }
  private GetProgramStartDate() {
    if (this.localProgramItem !== null && this.localProgramItem !== undefined && this.localProgramItem.Id > 0) {
      return this.localProgramItem.StartDate;
    } else {
      if (this.isimageIconClicked === false) {
        return this.newProgramStartDate!;
      }
      return this.alreadyselectedDate;
    }
  }
  // update information in local program item
  private updateInformationInLocalProgramItem() {
    this.localProgramItem = Object.assign({}, this.selectedProgramItem);
    this.GetSessionCheckInData();
    if (this.localProgramItem.Duration == null) {
      this.localProgramItem.Duration = this.allDurations.find(
        (obj: ProgramDurationItem) => obj.Minutes === 60,
      );
    }
    const newItem = ProgramTrackItem.createEmpty();
    this.defaultTrack = newItem;
    if (this.localProgramItem.Track == null) {
      this.localProgramItem.Track = newItem;
    }
    // update tags in component when program item is changed
    this.selectedTags = [];
    this.existingTags = [];
    this.updateTags();
    this.state.localProgramItem = this.localProgramItem;
  }
  @Watch('selectedProgramItem')
  private onPropertyChanged(value: ProgramItem, oldValue: ProgramItem) {
    const currentProgramItem = ProgramItem.prototype.SetProgramObject(value);
    const previousProgramItem = ProgramItem.prototype.SetProgramObject(
      oldValue,
    );
    this.showMultimediaFileUploadProcess = false;
    this.showFileUploadProcess = false;
    this.showAddVideoProcess = false;
    this.showPreRecordedAnalytics = false;
    this.showVirtualMeetingSessionAnalytics = false;
    this.showMeetAppLiveRecordingSessionAnalytics = false;

    currentProgramItem.VirtualMeetingLink = currentProgramItem.VirtualMeetingLink === null ? '' : currentProgramItem.VirtualMeetingLink;

    previousProgramItem.VirtualMeetingLink = previousProgramItem.VirtualMeetingLink === null ? '' : previousProgramItem.VirtualMeetingLink;
    if (
      !Common.prototype.CompareObjects(currentProgramItem, previousProgramItem)
    ) {
      // hide validation summary when switching the program
      this.visibleValidationSummary = false;
      // close add information section when switching the program
      this.showAddInformationProcess = false;
      this.showCheckedInParticipantDetails = false;
      this.updateInformationInLocalProgramItem();
      this.SetVodDetail();
    } else {
      if (value.Track === null) {
        this.localProgramItem!.Track = ProgramTrackItem.createEmpty();
      }
    }
    (this.v$ as any).$reset();
  }

  // Clear Messages List
  private ClearMessagesList() {
    this.messages = [];
  }

  // Update tags in component
  private updateTags() {
    const allAvailableTags = this.availableTags || [];
    for (const allAvailableTag of allAvailableTags) {
      this.existingTags.push({
        key: allAvailableTag.Id,
        value: allAvailableTag.TagName,
      });
    }
    if (typeof this.selectedProgramItem !== 'undefined') {
      const tags = this.selectedProgramItem.ProgramTags || [];
      if (typeof tags !== 'undefined') {
        for (const tag of tags) {
          this.selectedTags.push({ key: tag.Id, value: tag.TagName });
        }
      }
    }
  }
  private GetImageUrl() {
    return this.localProgramItem!.ImageUrl === ''
      ? this.missingImage
      : this.localProgramItem!.ImageUrl;
  }
  private GetImageFullUrl() {
    if (
      this.localProgramItem!.MainMediaFullUrl === '' ||
      this.localProgramItem!.MainMediaFullUrl === null
    ) {
      return '';
    } else {
      return this.localProgramItem!.MainMediaFullUrl;
    }
  }

  private GetFeaturedImageUrl() {
    return this.localProgramItem!.FeaturedImageUrl === ''
      ? this.featureMissingImage
      : this.localProgramItem!.FeaturedImageUrl;
  }
  private GetfeaturedimageFullUrl() {
    if (
      this.localProgramItem!.FeaturedOrginalImageUrl === '' ||
      this.localProgramItem!.FeaturedOrginalImageUrl === null
    ) {
      this.localProgramItem!.FeaturedImageWidth = this.defaultImageWidth;
      this.localProgramItem!.FeaturedImageHeight = this.defaultImageHeight;
      return this.featureMissingImage;
    } else {
      return this.localProgramItem!.FeaturedOrginalImageUrl;
    }
  }
  private CheckFeaturedImage() {
    return this.localProgramItem!.FeaturedOrginalImageUrl === '' ||
      this.localProgramItem!.FeaturedOrginalImageUrl === null
      ? false
      : true;
  }
  private clickOnImage(val: boolean) {
    this.isimageIconClicked = true;
    this.subModuleName = SubModulePrefix.IconImage;
    this.showImageProcess = val;
    setTimeout(() => {
      Root.emit(
        'selected-participant-group-is',
        this.selectedProgramItem!.ParticipantGroupIds,
      );
    }, 200);
  }

  private showAttachInformation(val: any) {
    setTimeout(() => {
      Root.emit(
        'selected-participant-group-is',
        this.selectedProgramItem!.ParticipantGroupIds,
      );
    }, 200);
    this.showAddInformationProcess = val.showAddInformation;
    this.localAllInformations = val.informations;
    Root.emit('linkedInformationData', val.informations);
    // scroll to information section.
    this.scrollToElement('programLinkedInformationDiv');
  }
  private onHoursChange(value: any) {
    if (value === '') {
      if (this.localProgramItem) {
        this.localProgramItem.Duration = null;
      }
    }
  }
  private onMinutesChange(value: any) {
    if (value === '') {
      if (this.localProgramItem) {
        this.localProgramItem.Duration = null;
      }
    }
  }
  private SetImageIconUrl(imgurl: string, mainMediaFullUrl: string) {
    this.localProgramItem!.ImageUrl = imgurl;
    this.localProgramItem!.MainMediaFullUrl = mainMediaFullUrl;
  }
  private ShowProgramtitleBelowFeaturedImage(
    isShowProgramtitleBelowFeaturedImage: boolean,
  ) {
    this.localProgramItem!.ShowFeatureImageBelowTitle = isShowProgramtitleBelowFeaturedImage;
  }
  // set featured image url in local program item when featured image modified.
  // this is the emit property from image process component
  private SetFeaturedImageIconUrl(
    thumbUrl: string,
    fullUrl: string,
    height: number,
    width: number,
    top: string,
    left: string,
  ) {
    this.localProgramItem!.FeaturedImageUrl = thumbUrl;
    this.localProgramItem!.FeaturedOrginalImageUrl = fullUrl;
    this.localProgramItem!.FeaturedImageWidth = width;
    this.localProgramItem!.FeaturedImageHeight = height;
    this.localProgramItem!.TopPosition = top;
    this.localProgramItem!.LeftPosition = left;
  }
  // Assign date value to start date returned from datepicker component
  private SetBrowserDateValue(date: Date) {
    if (date != null && this.localProgramItem != null) {
      this.localProgramItem.StartDate = date;
      this.alreadyselectedDate = date;
      this.GetProgramStartDate();
    }
  }
  // Assign selected tags returned from InputTag component
  private SetTagNamesValue(selectedTagNames: any) {
    if (selectedTagNames != null && this.localProgramItem != null) {
      if (typeof selectedTagNames !== 'undefined') {
        this.localProgramItem.ProgramTags = [] as ProgramTagItem[];
        for (const tag of selectedTagNames) {
          this.localProgramItem.ProgramTags.push(
            new ProgramTagItem(Number(tag.key), String(tag.value)),
          );
          this.selectedTags.push(tag);
        }
      }
    }
  }
  // Assign description returned from Editor component
  private SetDescriptionValue(description: string) {
    if (description != null && this.localProgramItem != null) {
      if (typeof description !== 'undefined') {
        this.localProgramItem.Description = description;
      }
    }
  }

  private programMultimediaItemShowImageUploadComponent(val: any) {
    this.showMultimediaFileUploadProcess = val.showFileUploadProcess;
    this.currentEditMultimediaItem = val.selectedMultimediaitem;
    this.subModuleName = SubModulePrefix.Multimedia;
  }

  private program360ImageItemShowImageUploadComponent(val: any) {
    this.showFileUploadProcess = val.showFileUploadProcess;
    this.currentEdit360ImageItem = val.selected360Imageitem;
  }

  private programMultimediaItemFileUploadCompleted() {
    setTimeout(() => {
      Root.emit(
        'selected-participant-group-is',
        this.selectedProgramItem!.ParticipantGroupIds,
      );
    }, 200);
    if (this.showMultimediaFileUploadProcess === true) {
      this.showMultimediaFileUploadProcess = false;
      setTimeout(() => {
        Root.emit('program-multimedia-item-imageupdated-operation', {
          selectedMultimediaitem: this.currentEditMultimediaItem,
        });
      }, 100);
    } else if (this.showFileUploadProcess === true) {
      this.showFileUploadProcess = false;
      setTimeout(() => {
        Root.emit('program-360image-item-imageupdated-operation', {
          selected360Imageitem: this.currentEdit360ImageItem,
        });
      }, 100);
    }
  }
  private SetMultimediaIconUrl(
    imgurl: string,
    mainMediaFullUrl: string,
    type: string,
  ) {
    if (this.showMultimediaFileUploadProcess === true) {
      this.currentEditMultimediaItem!.ThumbUrl = imgurl;
      this.currentEditMultimediaItem!.Url = mainMediaFullUrl;
      // image croppie doesn't return media type.
      if (type != null) {
        this.currentEditMultimediaItem!.Type = type;
      }
    } else if (this.showFileUploadProcess === true) {
      this.currentEdit360ImageItem!.ThumbUrl = imgurl;
      this.currentEdit360ImageItem!.Url = mainMediaFullUrl;
      this.currentEdit360ImageItem!.Type = this.imageType360Item;
    }
  }
  private GetPreviousIconUrl(
    imgUrl: string,
    mainMediaFullUrl: string,
    type: string,
  ) {
    if (this.showMultimediaFileUploadProcess === true) {
      this.currentEditMultimediaItem!.OldThumbUrl = imgUrl;
      this.currentEditMultimediaItem!.OldUrl = mainMediaFullUrl;
      this.currentEditMultimediaItem!.Type = type;
    } else if (this.showFileUploadProcess === true) {
      this.currentEdit360ImageItem!.OldThumbUrl = imgUrl;
      this.currentEdit360ImageItem!.OldUrl = mainMediaFullUrl;
      this.currentEdit360ImageItem!.Type = this.imageType360Item;
    }
  }
  private GetMultimediaFileUploadHeadingTitle() {
    if (this.showMultimediaFileUploadProcess === true) {
      return this.$t('Multimedia.Add');
    } else if (this.showFileUploadProcess === true) {
      return this.$t('360Images.Add');
    }
  }

  private GetMultimediaFileUploadSubHeadingTitle() {
    if (this.showMultimediaFileUploadProcess === true) {
      return this.$t('FileUpload.lblSelectMultimedia');
    } else if (this.showFileUploadProcess === true) {
      return this.$t('FileUpload.lblSelect360Image');
    }
  }

  private GetFileExtensions() {
    if (this.showMultimediaFileUploadProcess === true) {
      return 'png,.jpg,.jpeg,.mp4';
    } else if (this.showFileUploadProcess === true) {
      return '.png,.jpg,.jpeg';
    }
  }
  private GetValidationMessage() {
    if (this.showMultimediaFileUploadProcess === true) {
      return 'FileUpload.Upload.ErrorChooseImageOrVideoOnly';
    } else if (this.showFileUploadProcess === true) {
      return 'FileUpload.Upload.ErrorChooseImageOnly';
    }
  }
  private GetValidationType() {
    if (this.showMultimediaFileUploadProcess === true) {
      return 'lessthan';
    } else if (this.showFileUploadProcess === true) {
      return 'greaterthan';
    }
  }

  private GetIconImageSize() {
    if (this.showMultimediaFileUploadProcess === true) {
      return 150;
    } else if (this.showFileUploadProcess === true) {
      return 4096;
    }
  }
  private GetMultimediaImageType() {
    if (this.showMultimediaFileUploadProcess === true) {
      if (this.currentEditMultimediaItem != null) {
        return this.currentEditMultimediaItem.IsVideo === true ? 'Video' : this.currentEditMultimediaItem.Type;
      }
    } else if (this.showFileUploadProcess === true) {
      return this.currentEdit360ImageItem != null ? this.currentEdit360ImageItem.Type : '';
    }
    return '';
  }
  private GetMultimediaImageUrl() {
    if (this.showMultimediaFileUploadProcess === true) {
      if (this.currentEditMultimediaItem != null) {
        if (this.currentEditMultimediaItem.ThumbUrl !== '') {
          if (this.currentEditMultimediaItem.Type === this.video) {
            return this.missingVideo;
          } else {
            return this.currentEditMultimediaItem.ThumbUrl;
          }
        }
      }
    } else if (this.showFileUploadProcess === true) {
      return this.currentEdit360ImageItem != null
        ? this.currentEdit360ImageItem.ThumbUrl !== ''
          ? this.currentEdit360ImageItem.ThumbUrl
          : this.missingImage
        : this.missingImage;
    }

    return this.missingImage;
  }
  private GetMultimediaImageFullUrl() {
    if (this.showMultimediaFileUploadProcess === true) {
      if (this.currentEditMultimediaItem != null) {
        if (this.currentEditMultimediaItem.Url !== '') {
          if (this.currentEditMultimediaItem.Type === this.video) {
            return this.missingVideo;
          } else {
            return this.currentEditMultimediaItem.Url;
          }
        }
      }
    } else if (this.showFileUploadProcess === true) {
      return this.currentEdit360ImageItem != null
        ? this.currentEdit360ImageItem.Url !== ''
          ? this.currentEdit360ImageItem.Url
          : this.missingImage
        : this.missingImage;
    }

    return this.missingImage;
  }

  private GetAcceptedFileTypes() {
    const acceptedFileTypes: string[] = [];
    if (this.showMultimediaFileUploadProcess === true) {
      acceptedFileTypes.push('image/png');
      acceptedFileTypes.push('image/x-png');
      acceptedFileTypes.push('image/jpg');
      acceptedFileTypes.push('image/jpeg');
      acceptedFileTypes.push('video.*');
    } else if (this.showFileUploadProcess === true) {
      acceptedFileTypes.push('image/png');
      acceptedFileTypes.push('image/x-png');
      acceptedFileTypes.push('image/jpg');
      acceptedFileTypes.push('image/jpeg');
    }
    return acceptedFileTypes;
  }
  private GetVodDetailObject() {
    if (this.selectedItem!.VodDetail === null || this.selectedItem!.VodDetail === undefined) {
      return VodDetail.createEmpty();
    } else {
      return this.selectedItem!.VodDetail;
    }
  }
  private GetCaptionFilesAcceptedTypesForVOD() {
    return 'vtt|srt';
  }
  private GetAcceptedVideoFilesForVod() {
    return 'mp4|webM|3gp|mpeg|ogg|mov|wmv|flv|avchd|mkv|avi';
  }
  private GetVodDetailValues(): VodVideoMetricsRequest {
    const ObjVodVideoMetricsRequest: VodVideoMetricsRequest = new VodVideoMetricsRequest();
    ObjVodVideoMetricsRequest.VideoId = this.localProgramItem!.VodDetail!.VodVideoDetail!.Id;
    ObjVodVideoMetricsRequest.VideoDetailId = this.localProgramItem!.VodDetail!.Id;

    ObjVodVideoMetricsRequest.ApplicationId =  this.localProgramItem!.VodDetail!.VodDetailApplicationInstance!.ApplicationId;

    ObjVodVideoMetricsRequest.ApplicationInstanceId = this.localProgramItem!.VodDetail!.VodDetailApplicationInstance!.EventId;
    ObjVodVideoMetricsRequest.EntityId = this.localProgramItem!.VodDetail!.VodDetailApplicationInstance!.EntityId;
    ObjVodVideoMetricsRequest.EntityType = 'Program';
    return ObjVodVideoMetricsRequest;
  }
  private SetVodDetailId(event: any) {
    if (this.localProgramItem!.VodDetail === undefined) {
      this.localProgramItem!.VodDetail = VodDetail.createEmpty();
    }
    this.localProgramItem!.VodDetail = event;
    this.SetVodDetail();
  }

  private SetVodDetail() {
    this.selectedItem!.VirtualMeetingType = VodTypeEnum[1].toString();
    this.selectedItem!.Id = this.localProgramItem!.Id;
    this.selectedItem!.Title = this.localProgramItem!.Title;
    this.selectedItem!.VodDetail = this.localProgramItem!.VodDetail!;
    this.selectedVodRenderer =  VodService.GetEntityObjectByType(1, this.GetVodDetailObject(), this.selectedItem!.Id,this.localProgramItem!.StartDate,this.fileUploadGetAssetPreUploadUrl,this.GetAcceptedVideoFilesForVod(),this.GetCaptionFilesAcceptedTypesForVOD(), this.selectedMeetingOption);
    Root.emit('voddetail-updated',this.selectedVodRenderer);
  }

  private ShowProgramEditDetailView() {
    return (
      this.localProgramItem &&
      !this.showImageProcess &&
      !this.showAddInformationProcess &&
      !this.showFileUploadProcess &&
      !this.showMultimediaFileUploadProcess &&
      !this.showAddVideoProcess &&
      !this.showPreRecordedAnalytics &&
      !this.showVirtualMeetingSessionAnalytics &&
      !this.showMeetAppLiveRecordingSessionAnalytics &&
      !this.showCheckedInParticipantDetails
    );
  }
  private ShowImageProcessSection() {
    return (
      this.localProgramItem &&
      this.showImageProcess &&
      !this.showAddInformationProcess &&
      !this.showFileUploadProcess &&
      !this.showMultimediaFileUploadProcess &&
      !this.showAddVideoProcess &&
      !this.showPreRecordedAnalytics &&
      !this.showVirtualMeetingSessionAnalytics &&
      !this.showMeetAppLiveRecordingSessionAnalytics &&
      !this.showCheckedInParticipantDetails
    );
  }
  private ShowAddInformationSection() {
    return (
      this.localProgramItem!.Id > 0 &&
      this.showAddInformationProcess &&
      !this.showFileUploadProcess &&
      !this.showMultimediaFileUploadProcess &&
      !this.showAddVideoProcess &&
      !this.showPreRecordedAnalytics &&
      !this.showVirtualMeetingSessionAnalytics &&
      !this.showMeetAppLiveRecordingSessionAnalytics
    );
  }
  private ShowFileUploadSection() {
    return (
      this.localProgramItem!.Id > 0 &&
      (this.showMultimediaFileUploadProcess || this.showFileUploadProcess)
    );
  }
  private ShowAddVideoSection() {
    return(
      this.localProgramItem &&
      !this.showImageProcess &&
      !this.showAddInformationProcess &&
      !this.showFileUploadProcess &&
      !this.showMultimediaFileUploadProcess &&
      !this.showPreRecordedAnalytics &&
      this.showAddVideoProcess &&
      !this.showVirtualMeetingSessionAnalytics &&
      !this.showMeetAppLiveRecordingSessionAnalytics &&
      !this.showCheckedInParticipantDetails
    );
  }
  private ShowPreRecordedVideoChartSection() {
    return(
      this.localProgramItem &&
      !this.showImageProcess &&
      !this.showAddInformationProcess &&
      !this.showFileUploadProcess &&
      !this.showMultimediaFileUploadProcess &&
      !this.showAddVideoProcess &&
      this.showPreRecordedAnalytics &&
      !this.showVirtualMeetingSessionAnalytics &&
      !this.showMeetAppLiveRecordingSessionAnalytics &&
      !this.showCheckedInParticipantDetails
    );
  }

  private ShowVirtualMeetingSessionAnalytics() {
    return(
      this.localProgramItem &&
      !this.showImageProcess &&
      !this.showAddInformationProcess &&
      !this.showFileUploadProcess &&
      !this.showMultimediaFileUploadProcess &&
      !this.showAddVideoProcess &&
      !this.showPreRecordedAnalytics &&
      this.showVirtualMeetingSessionAnalytics &&
      !this.showMeetAppLiveRecordingSessionAnalytics &&
      !this.showCheckedInParticipantDetails
    );
  }

  private ShowMeetAppLiveRecordingSessionAnalytics() {
    return(
      this.localProgramItem &&
      !this.showImageProcess &&
      !this.showAddInformationProcess &&
      !this.showFileUploadProcess &&
      !this.showMultimediaFileUploadProcess &&
      !this.showAddVideoProcess &&
      !this.showPreRecordedAnalytics &&
      !this.showVirtualMeetingSessionAnalytics &&
      this.showMeetAppLiveRecordingSessionAnalytics &&
      !this.showCheckedInParticipantDetails
    );
  }
  private ShowCheckedInParticipantDetails() {
    return(
      this.localProgramItem &&
      !this.showImageProcess &&
      !this.showAddInformationProcess &&
      !this.showFileUploadProcess &&
      !this.showMultimediaFileUploadProcess &&
      !this.showAddVideoProcess &&
      !this.showPreRecordedAnalytics &&
      !this.showVirtualMeetingSessionAnalytics &&
      !this.showMeetAppLiveRecordingSessionAnalytics &&
      this.showCheckedInParticipantDetails
    );
  }

  // Delete Program button clicked
  private ClickDeleteProgram() {
    // show modal popup for confirmation
    this.modalPopupVisible = true;
    this.showCloseButton = true;
    this.showSection_ModalPopup = this.NotificationProgramDeleted;
    this.modalPopupContent = this.$t('Program.Delete').toString();
  }
  // Delete Program
  private DeleteProgram() {
    const signalObject = this.SendNotificationRequest(true);
    FetchData('/Program/DeleteProgram', JSON.stringify({
      id: this.localProgramItem!.Id,
    }))
      .then((response) => {
        if (response.ValidationErrors.length > 0) {
          // Server side validation failuere
          alert(this.$t(response.ValidationErrors[0]));
        } else {
          // Successful delete
          this.SendNotificationRequestToQueue(
            signalObject,
            this.localProgramItem!,
          );
          this.$emit('program-item-delete-operation', this.localProgramItem);
        }
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  // function to move scroll to the element
  private scrollToElement(id: string) {
    setTimeout(() => {
      const el = document.getElementById(id);
      if (el) {
        el.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
          inline: 'nearest',
        });
      }
    }, 200);
  }
  private beforeUnmount() {
    Root.off('update-attached-program-items-with-session-questions');
    Root.off('update-attached-program-items-with-evaluation-questions');
  }
  private getInformationString(val: boolean) {
    this.isTimezoneInfoVisible = val;
  }
  private SetListParticipantGroups(listOfGroups: ParticipantGroups[], hasSelectedGroups: boolean) {
    // set groups in local program item
    this.hasSelectedGroups = hasSelectedGroups;
    if(this.selectedProgramItem !== null && this.selectedProgramItem !== undefined && listOfGroups !== null && listOfGroups !== undefined) {
      this.localProgramItem!.ParticipantGroups = listOfGroups;
      this.selectedProgramItem.ParticipantGroupIds = [];
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < listOfGroups.length; i++) {
        this.selectedProgramItem.ParticipantGroupIds.push(listOfGroups[i].Id);
      }
    }
    if(this.localProgramItem !== null && this.localProgramItem !== undefined && this.localProgramItem.Id === 0) {
      this.localProgramItem.HideFromEventWebpage = this.localProgramItem.ParticipantGroups.length > 0 ? true : false;
    }
  }
  private setListInLocalItem(listOfGroups: ParticipantGroups[]) {
    // set groups in local program item
    this.localProgramItem!.ParticipantGroups = listOfGroups;
  }
}
export default toNative(ProgramEditItemPane);
