import { t } from '@/main';
import ActionButtonViewModel from '../ActionButtonViewModel';
export default class ImportDataViewModel {
  public static createEmpty(): ImportDataViewModel {
    return new ImportDataViewModel();
  }
  constructor(
    public Heading: string = '',
    public Description: string = '',
    public FileOperation: ActionButtonViewModel[] = [],
  ) {}
  public static GetImportFileData(moduleType: number) {
    switch(moduleType) {
    case 0:
      return new ImportDataViewModel(t('Import.ProgramImport.Heading').toString(), t('Import.ProgramImport.Description').toString(),[{Heading: t('Import.ProgramImport.ActionButton.CreateNewHeading').toString(), Description: t('Import.ProgramImport.ActionButton.CreateNewDiscription').toString(), ButtonAction: 0, ButtonText: t('Import.ProgramImport.ActionButton.CreateNewButtonText').toString()},{Heading: t('Import.ProgramImport.ActionButton.UpdateHeading').toString(), Description: t('Import.ProgramImport.ActionButton.UpdateDiscription').toString(), ButtonAction: 1, ButtonText: t('Import.ProgramImport.ActionButton.UpdateButtonText').toString()}]);
    default:
      return ImportDataViewModel.createEmpty();
    }
  }
}
