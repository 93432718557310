
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { ProgramEvaluationQuestionsItem } from '@/entities/Program/ProgramEvaluationQuestionsItem';
import { ProgramEvaluationQuestionType } from '@/entities/Program/ProgramEvaluationQuestionType';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import EvaluationQuestionListing from '@/components/Program/EvaluationQuestions/EvalutationQuestionListing.vue';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';

import { ProgramEvaluationQuestionsSignalRModel } from '@/signalRRequest/Program/ProgramEvaluationQuestionsSignalRModel';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { DateTimeOptions } from 'vue-i18n';
import Common from '@/helper/Common';
import { FetchData, Root, Store } from '@/main';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { reactive } from 'vue';
import ApiContentType from '@/enums/ApiContentType';
@Component({
  components: {
    loading,
    ModalPopup,
    EvaluationQuestionListing,
    ValidationSummary,
  },
})
class EvaluationQuestionsEditItemPane extends Vue {
  @Prop() private selectedEvaluationQuestion?: ProgramEvaluationQuestionsItem;
  @Prop() private programItems?: ProgramItem[];
  @Prop()
  private programEvaluationQuestionTypes?: ProgramEvaluationQuestionType[];
  @Prop() private globalProgramEvaluation?: boolean;
  private localEvaluationQuestion?: ProgramEvaluationQuestionsItem = ProgramEvaluationQuestionsItem.createEmpty();
  private localProgramEvaluationQuestionTypes?: ProgramEvaluationQuestionType[] = [];
  private loaderBorderColor = '';
  private showLoading = false;
  private beforeEditingCache: ProgramEvaluationQuestionsItem = ProgramEvaluationQuestionsItem.createEmpty();
  private isMobileView = false;
  private selectedProgramIds: number[] = [];
  private selectedPrograms: ProgramItem[] = [];
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent: string = this.getRemoveEvaluationQuestionMessage();
  private errors: string[] = [];
  private messages: string[] = [];
  private excelIcon: string = require('@/assets/Images/Excel.jpeg');
  private fileDownload = require('js-file-download');
  private deleteIcon = require('@/assets/Images/Delete-icon.svg');
  private selectAllProgramItemsCheckbox = false;
  private reloadProgramItems = false;
  private localGlobalProgramEvaluation = false;
  private selectedProgramNames = '';
  // validation summary
  private visibleValidationSummary = false;
  private validationErrorFields: string[] = [];
  private storeHelper: StoreHelper = new StoreHelper(Store);
  // Date format to show for a selected Date time
  private dateOptions: DateTimeOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  };
  // Create a new Date format from dateoptions specified
  private dateFormat = new Intl.DateTimeFormat();
  private state = reactive({
    localEvaluationQuestion: null as ProgramEvaluationQuestionsItem | null,
  });

  private rules = {
    localEvaluationQuestion: {
      Question: {
        required,
        minLength: minLength(1),
      },
    },
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);
  // Validation summary
  private CloseValidationSummary(visible: boolean) {
    this.visibleValidationSummary = visible;
  }
  // Created Event of Component
  private created() {
    // Assign value to the local variable of evaluation Question
    if (this.selectedEvaluationQuestion != null) {
      this.localEvaluationQuestion = Object.assign(
        {},
        this.selectedEvaluationQuestion,
      );
      this.state.localEvaluationQuestion = this.localEvaluationQuestion;
      this.beforeEditingCache = Object.assign(
        {},
        this.selectedEvaluationQuestion,
      );
    }
  }

  // Mounted Event of Component
  private mounted() {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, this.dateOptions);
    this.localGlobalProgramEvaluation = this.globalProgramEvaluation!;
    // Assign value to the local variable of evaluation Question
    if (this.selectedEvaluationQuestion != null) {
      this.localEvaluationQuestion = Object.assign(
        {},
        this.selectedEvaluationQuestion,
      );
      this.state.localEvaluationQuestion = this.localEvaluationQuestion;
      this.beforeEditingCache = Object.assign(
        {},
        this.selectedEvaluationQuestion,
      );
    }
    // Assign value to the local variable of Program Question Types to show in dropdown
    if (this.programEvaluationQuestionTypes != null) {
      this.localProgramEvaluationQuestionTypes = this.programEvaluationQuestionTypes;
    }

    if (this.localEvaluationQuestion != null) {
      this.selectedProgramIds = [];
      this.selectedPrograms = [];
      if (this.localEvaluationQuestion.SelectedPrograms !== 'All') {
        // Set selected program Ids from list of attached program Ids
        if (this.localEvaluationQuestion.SelectedProgramIds) {
          const itemsArr = this.localEvaluationQuestion.SelectedProgramIds.split(
            ',',
          );

          itemsArr.forEach((item) => {
            this.selectedProgramIds.push(Number(item));
            const objIndex = this.programItems!.findIndex(
              (obj: ProgramItem) => obj.Id === Number(item),
            );
            if (objIndex > -1) {
              this.selectedPrograms.push(this.programItems![objIndex]);
            }
          });
        }
      }
    }
    // check for ipad and mobile view
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }

    // whenever all program or selected program radio button selected,
    // selected program list in edit pane will be updated.
    Root.on('update-evaluation-selected-programs', (object: any) => {
      if (object.isallSelected) {
        this.selectedPrograms = [];
      } else {
        const selectedProgramIds: number[] = object.selectedProgramIds;
        selectedProgramIds.forEach((element) => {
          const objIndex = this.programItems!.findIndex(
            (obj: ProgramItem) => obj.Id === element,
          );
          if (objIndex > -1) {
            this.selectedPrograms.push(this.programItems![objIndex]);
          }
        });
      }
    });

    Root.on('global-program-evaluation', (globalEvaluation: boolean) => {
      this.localGlobalProgramEvaluation = globalEvaluation;
      this.selectedPrograms = [];
      this.localEvaluationQuestion!.SelectedProgramIds = '';
      if (!globalEvaluation) {
        this.localEvaluationQuestion!.SelectedPrograms = '';
      } else {
        this.localEvaluationQuestion!.SelectedPrograms = 'All';
      }
    });

    // make scroll to top when session question item selected
    Root.on(
      'new-evaluation-question-edit-item-pane',
      (isNewEvaluationQuestionEditItemPane: boolean) => {
        if (isNewEvaluationQuestionEditItemPane) {
          // scroll to top if any program edit or new program clicked
          if (!this.isMobileView) {
            Common.ScrollToTop('evaluationQuestionEditMode');
          }
        }
      },
    );

    const self = this;
    Root.on(
      'program-evaluation-questions-updated',
      (evaluationQuestionsItem: ProgramEvaluationQuestionsItem) => {
        self.UpdateLocalEvaluationQuestion(self, evaluationQuestionsItem);
      },
    );
  }

  // Get message to delete from Translations
  private getRemoveEvaluationQuestionMessage(): string {
    return this.$t('EvaluationQuestions.DeleteEvaluationQuestion').toString();
  }

  // Click on Yes/Cancel Button of Popup
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      this.DeleteConfirmed();
    } else {
      return;
    }
  }

  // Show Popup for Deletion
  private Delete() {
    this.modalPopupVisible = true;
  }
  // Delete a Session Question
  private DeleteConfirmed() {
    const signalObject = this.SendNotificationRequest(true);
    FetchData('/Program/DeleteEvaluation', JSON.stringify({
      programEvaluationId: this.localEvaluationQuestion!.Id,
    }))
      .then((response) => {
        if (response.ValidationErrors.length > 0) {
          // Server side validation failuere
          alert(this.$t('Message.UnableToDelete'));
        } else {
          // Successful update / save
          this.SendNotificationRequestToQueue(
            signalObject,
            this.localEvaluationQuestion!,
          );
          Root.emit(
            'program-evaluationquestion-item-delete-operation',
            this.localEvaluationQuestion!.Id,
          );
        }
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  private SendNotificationRequest(isDelete: boolean): SignalRModelRequest {
    const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
      Root,
      Store,
      this.$t,
    );
    signalR.ShowNotificationsPopup();
    Root.emit('program-evaluationquestion-tab-select-operation');
    let signalObject: SignalRModelRequest = new SignalRModelRequest();
    if (isDelete) {
      signalObject = signalR.SingleDeleteEvaluationQuestionSignalRModelRequest;
      const arr: number[] = [];
      arr.push(this.localEvaluationQuestion!.Id);
      signalObject.SelectedIds = arr;
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        selectedCount: arr.length, // Just single item delete
        user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
      }).toString();
    } else {
      if (this.localEvaluationQuestion!.Id === 0) {
        signalObject = signalR.SingleCreateEvaluationQuestionSignalRModelRequest;
      } else {
        signalObject = signalR.SingleUpdateEvaluationQuestionSignalRModelRequest;
        const arr: number[] = [];
        arr.push(this.localEvaluationQuestion!.Id);
        signalObject.SelectedIds = arr;
      }
      signalObject.Description = this.$t(signalObject.SubmitMessage, {
        user: signalR.GetUserName(signalObject, this.$t('UserName.You').toString()),
      }).toString();
    }
    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    signalObject.ApplicationId = Number(reqInfo.ApplicationId);
    signalObject.EventId = Number(reqInfo.ApplicationInstanceId);
    signalObject.JSONData = JSON.stringify({selectedName: this.localEvaluationQuestion!.Question});
    signalR.DisableEvaluationQuestionsAndAddToNotificationPopup(signalObject);
    return signalObject;
  }

  private SendNotificationRequestToQueue(
    signalObject: SignalRModelRequest,
    evaluationQuestion: ProgramEvaluationQuestionsItem,
  ) {
    const signalR: ProgramEvaluationQuestionsSignalRModel = new ProgramEvaluationQuestionsSignalRModel(
      Root,
      Store,
      this.$t,
    );
    // Case of Single Create as we get the id once response come from DB
    if (signalObject.SelectedIds.length === 0) {
      const arr: number[] = [];
      arr.push(evaluationQuestion.Id);
      signalObject.SelectedIds = arr;
    }
    signalObject.JSONData = signalR.GetEvaluationQuestionToSendToSignalR(evaluationQuestion);
    signalR.PerformEvaluationSingleOperation(signalObject);
  }

  private IsAnonymousClicked(event: any) {
    const programItemChecked = event.target.checked;
    this.localEvaluationQuestion!.Anynomous = programItemChecked;
  }
  private Cancel(val: any) {
    val.$reset();
    const existingitem = this.beforeEditingCache;
    this.$emit(
      'program-evaluationQuestion-item-cancel-operation',
      existingitem,
    );
    Root.emit(
      'program-evaluationQuestion-item-cancel-operation',
      existingitem,
    );
  }

  // Add/ Edit a Session Question
  private async Submit(val: any) {
    val.$touch();
    const res = !await val.$validate();
    if (res) {
      if (val.localEvaluationQuestion.Question!.$error === true) {
        this.validationErrorFields = [];
        this.validationErrorFields.push(this.$t('EvaluationQuestions.Question').toString());
      }
      this.visibleValidationSummary = true;
      return;
    }
    val.$reset();
    this.visibleValidationSummary = false;

    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    this.localEvaluationQuestion!.ApplicationInstanceId = reqInfo.ApplicationInstanceId;
    const signalObject = this.SendNotificationRequest(false);
    // No errors submitting.
    FetchData('/Program/CreateNewEvaluation',
      JSON.stringify(this.localEvaluationQuestion))
      .then((response) => {
        if (response.ValidationErrors.length > 0) {
          // Server side validation failuere
          alert(this.$t(response.ValidationErrors[0]));
        } else {
          // Successful update / save
          const item = response.EvaluationQuestionItem;
          // Root.emit('program-evaluationquestion-item-edit-operation', item);
          this.SendNotificationRequestToQueue(signalObject, item as ProgramEvaluationQuestionsItem);
        }
        this.showLoading = false;
        setTimeout(() => {
          this.ClearMessagesList();
        }, 5000);
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  // Clear Messages List
  private ClearMessagesList() {
    this.messages = [];
  }

  // Export Session Questions Data for a particular Session Question
  private ExportSingleEvaluationQuestionResultToExcel() {
    this.showLoading = true;
    FetchData('/FileDownload/ExportProgramEvaluation', JSON.stringify({
      programEvaluationId: this.localEvaluationQuestion!.Id,
    }), ApiContentType.Excel)
      .then((response) => {
        this.fileDownload(response, 'EvaluationQuestions.xlsx');
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  private UpdateLocalEvaluationQuestion(self: any, val: ProgramEvaluationQuestionsItem) {
    self.v$.$reset();
    // hide validation summary when switching the evaluation item
    self.visibleValidationSummary = false;
    self.localEvaluationQuestion = Object.assign({}, val);
    self.beforeEditingCache = Object.assign({}, val);
    if (self.localEvaluationQuestion != null) {
      self.selectedProgramIds = [];
      self.selectedPrograms = [];
      if (self.localEvaluationQuestion!.SelectedPrograms !== 'All') {
        // Set selected program Ids from list of attached program Ids
        if (self.localEvaluationQuestion!.SelectedProgramIds) {
          const itemsArr = self.localEvaluationQuestion!.SelectedProgramIds!.split(
            ',',
          );
          itemsArr.forEach((item: number) => {
            self.selectedProgramIds.push(Number(item));
            const objIndex = self.programItems!.findIndex(
              (obj: ProgramItem) => obj.Id === Number(item),
            );
            if (objIndex > -1) {
              self.selectedPrograms.push(self.programItems![objIndex]);
            }
          });
        }
      }
    }
  }
  // Watch when the selected evaluation question is updated from list
  @Watch('selectedEvaluationQuestion')
  private onPropertySelectedEvaluationQuestionChange(val: ProgramEvaluationQuestionsItem) {
    // hide validation summary when switching the evaluation item
    this.visibleValidationSummary = false;
    this.localEvaluationQuestion = Object.assign({}, val);
    this.beforeEditingCache = Object.assign({}, val);
    if (this.localEvaluationQuestion != null) {
      this.selectedProgramIds = [];
      this.selectedPrograms = [];
      if (this.localEvaluationQuestion.SelectedPrograms !== 'All') {
        // Set selected program Ids from list of attached program Ids
        if (this.localEvaluationQuestion.SelectedProgramIds) {
          const itemsArr = this.localEvaluationQuestion.SelectedProgramIds.split(
            ',',
          );
          itemsArr.forEach((item) => {
            this.selectedProgramIds.push(Number(item));
            const objIndex = this.programItems!.findIndex(
              (obj: ProgramItem) => obj.Id === Number(item),
            );
            if (objIndex > -1) {
              this.selectedPrograms.push(this.programItems![objIndex]);
            }
          });
        }
      }
    }
    this.state.localEvaluationQuestion = this.localEvaluationQuestion;
  }

  // Set selected program Items
  private SetSelectedProgramItems(selectedProgramItems: ProgramItem[]) {
    this.selectedProgramIds = [];
    // this.selectedProgramNames="";
    selectedProgramItems.forEach((element) => {
      if (this.selectedProgramIds.indexOf(element.Id) === -1) {
        this.selectedProgramIds.push(element.Id);
      }
    });
    this.localEvaluationQuestion!.SelectedProgramIds = this.selectedProgramIds.toString();
  }
  @Watch('globalProgramEvaluation')
  private onGlobalProgramEvaluationChanged(val: boolean) {
    this.localGlobalProgramEvaluation = val;
  }
}
export default toNative(EvaluationQuestionsEditItemPane);
