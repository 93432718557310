
import { CheckedInParticipantDetailViewModel } from '@/entities/CheckedInParticipantDetailViewModel';
import Paginate from 'vuejs-paginate-next';
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import moment from 'moment';
import { StoreHelper } from '@/store/StoreHelper';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { Store } from '@/main';
@Component({
  components: {
    Paginate,
  },
})
class CheckedInParticipantDetails extends Vue {
  @Prop() private checkedInParticipantData!: CheckedInParticipantDetailViewModel[];
  private isMobileView = false;
  private page = 1;
  private pageCount = 3;
  private chunkSize = 10;
  private paginationPageMargin = 2;
  private pagiantionPreviewText = 'Prev';
  private pagiantionNextText = 'Next';
  private paginationPageRange = 3;
  private isIEbrowser = false;
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private timeZones: string = this.storeHelper.GetActiveEventIanaTimeZoneFromStore();
  private dateFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat();
  private localCheckedInParticipantData: CheckedInParticipantDetailViewModel[] = [];
  private isDataLoading = false;
  private loadingImage: string = require('@/assets/Images/loading.png');
  private mounted() {
    this.isDataLoading = true;
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.InitializeTimeFormat();
    this.SetPaginationData();
  }
  private SetPaginationData() {
    this.pageCount = Math.floor(this.checkedInParticipantData.length / this.chunkSize) + ((this.checkedInParticipantData.length % this.chunkSize) > 0 ? 1 : 0);
    this.clickCallback(1);
  }
  private clickCallback(pageNum: any) {
    if(this.checkedInParticipantData !== null && this.checkedInParticipantData !== undefined) {
      this.localCheckedInParticipantData = Array.from(this.checkedInParticipantData);
      this.localCheckedInParticipantData = this.localCheckedInParticipantData.splice(pageNum*this.chunkSize - this.chunkSize, this.chunkSize);
    }
  }
  private GetDescription() {
    return this.$t('SessionCheckIn.Description', {totalCheckedInParticipants: this.checkedInParticipantData.length, plural: this.checkedInParticipantData.length > 1 ? 's' : ''});
  }
  private FormatDateTime(date: string): string {
    const datepublished: Date = moment(date).toDate();
    if (!this.isIEbrowser) {
      return this.dateFormat.format(datepublished).toUpperCase();
    } else {
      const ieDateForamt = moment(datepublished).tz(this.timeZones).format('DD-MM-YYYY, h:mm a');
      return ieDateForamt;
    }
  }
  private InitializeTimeFormat() {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    if (this.timeZones !== '' && this.timeZones !== null && !this.isIEbrowser) {
      this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: this.timeZones,
      });
    } else {
      this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
    }
  }
  private GetFullName(firstName: string, lastName: string) {
    return firstName + ' ' + lastName;
  }
  private Close() {
    this.$emit('show-checked-in-participant-details', false);
  }
  @Watch('checkedInParticipantData')
  private CheckChangeInCheckedInParticipantData() {
    this.SetPaginationData();
    this.isDataLoading = false;
  }
}
export default toNative(CheckedInParticipantDetails);
